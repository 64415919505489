import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ExpandIcon } from "../../assets/menu_expand_icon.svg";
import styled from "styled-components";

function MenuPanel() {
  const [menuOpen, setMenuOpen] = useState('');
  const navigate = useNavigate();

  const navigateTo = (path) => {
    setMenuOpen('');
    if (path === '/signOut') {
      navigate('/');
    } else {
      navigate(path.toLowerCase());
    }
  };

  const openMenu = (menu) => {
    setMenuOpen(menuOpen === menu ? '' : menu);
  };

  const atLocation = (location, partial = false) => {
    const currentPath = window.location.pathname.toLowerCase();
    return partial ? currentPath.startsWith(location.toLowerCase()) : currentPath === location.toLowerCase();
  };

  return (
    <Root id="menu-panel-root">
      {menuOpen && <MenuCloser id="menu-closer" onClick={() => setMenuOpen('')} />}

      <MenuItem
        id="menu-item-home"
        onClick={() => navigateTo("/queue")}
        className={atLocation('/queue') ? 'selected' : ''}
        selected={atLocation('/queue')}
      >
        Home
        <Selection id="selection-home" show={atLocation('/queue')} />
      </MenuItem>

      <MenuItem
        id="menu-item-manage-plans"
        onClick={() => openMenu('managePlans')}
        className={`${menuOpen === 'managePlans' ? 'selected managePlansOpen' : ''}`}
        selected={menuOpen === 'managePlans'}
      >
        Manage plans
        <ExpandIcon id="expand-icon-manage-plans" className={menuOpen === 'managePlans' ? 'open' : ''} />
        {menuOpen === 'managePlans' && (
          <SubMenu id="sub-menu-manage-plans">
            <SubMenuItem
              id="sub-menu-item-processing-plans"
              onClick={() => navigateTo("/processing")}
              className={atLocation('/processing') ? 'selected' : ''}
            >
              Processing plans
            </SubMenuItem>
            <SubMenuItem
              id="sub-menu-item-submitted-plans"
              onClick={() => navigateTo("/submitted")}
              className={atLocation('/submitted') ? 'selected' : ''}
            >
              Submitted plans
            </SubMenuItem>
          </SubMenu>
        )}
      </MenuItem>

      <MenuItem
        id="menu-item-support"
        onClick={() => navigateTo("/support")}
        className={atLocation('/support', true) ? 'selected' : ''}
        selected={atLocation('/support', true)}
      >
        Support
        <Selection id="selection-support" show={atLocation('/support', true)} />
      </MenuItem>

      <MenuItem
        id="menu-item-sign-out"
        onClick={() => navigateTo('/signOut')}
        noHover={true}
        active={atLocation('/signOut')}
      >
        Sign out
        <Selection id="selection-sign-out" show={atLocation('/signOut')} />
      </MenuItem>
    </Root>
  );
}

const Root = styled.div`  
  position: absolute;  
  bottom: 0;  
  left: 0;  
  right: 0;  
  padding: 0 32px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial !important;  
  display: flex;  
  flex-direction: row;  
  gap: 8px;  
  justify-content: flex-end;  
  box-sizing: border-box;  
  transition: width 0.25s;  
  overflow: visible;  
`;

const MenuCloser = styled.div`  
  position: fixed;  
  top: 0;  
  bottom: 0;  
  left: 0;  
  right: 0;  
  background-color: transparent;  
  z-index: 5;  
  cursor: pointer;  
`;

const MenuItem = styled.button`  
  position: relative;  
  color: #323334;  
  background-color: #FAF8F2;  
  font-feature-settings: 'clig' off, 'liga' off;  
  font-family: "Enterprise Sans VF", Helvetica, Arial !important;  
  font-size: 16px;  
  font-style: normal;  
  font-weight: 400;  
  line-height: 24px;  
  cursor: pointer;  
  display: flex;  
  padding: 8px;  
  justify-content: center;  
  align-items: center;  
  margin-bottom: 5px;  
  border-radius: 4px;  
  border: 3px solid transparent;
  
  &:hover {  
    border-color: #0C55B8;
  }  
  
  &.selected {  
    border-color: transparent;
  }  
  
  &.managePlansOpen {  
    border-color: #0C55B8;
  }  
  
  & svg.profileIcon {  
    position: relative;  
    margin-right: 4px;  
    top: -2px;  
  }  
  
  & svg.open {  
    transform: rotate(180deg);  
  }  
  
  & svg.logout {  
    margin-left: 4px;  
  }  
`;


const SubMenu = styled.div`  
  position: absolute;  
  top: 100%;  
  margin-top: 12px;  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  border: 1px solid var(--neutral-50, #929496);  
  background: var(--neutral-0, #FFF);  
  box-shadow: 0px 4px 8px 0px rgba(25, 25, 26, 0.16);  
  z-index: 10;  
  margin-left: 20px;
`;

const SubMenuItem = styled.button`  
  display: flex;  
  padding: 12px 16px;  
  align-items: center;  
  gap: 4px;  
  flex: 1 0 0;  
  color: var(--neutral-90, #323334);  
  font-feature-settings: 'clig' off, 'liga' off;  
  font-family: "Enterprise Sans VF", Helvetica, Arial !important;  
  font-size: 16px;  
  font-style: normal;  
  font-weight: 400;  
  line-height: 20px;  
  width: max-content;  
  min-width: 100%;  
  border: 3px solid transparent;
  background: transparent;  
  cursor: pointer;  
  position: relative;  
  
  &:hover {  
    border-color: #0C55B8;
  }  
  
  &.selected {  
    background: #EEF4FF;  
  
    &::before {  
      content: '';  
      position: absolute;  
      top: -2px;  
      bottom: -3px;  
      left: -3px;
      width: 4px;  
      background-color: #0C55B8;  
    }  
  }  
  
  &.noselect {  
    &:hover {  
      border-color: transparent;  
    }  
  }  
`;

const Selection = styled.div`  
  position: absolute;  
  left: 0;  
  right: 0;  
  height: 4px;  
  bottom: -8px;  
  background-color: #0C55B8;  
  display: ${props => props.show ? 'block' : 'none'};  
`;

export default MenuPanel;