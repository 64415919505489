import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { fetchPDFUrl } from '../../services/endpoints';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const PDFViewerSection = ({ productId, isLinked, zoomLevel, pageNumber, scrollTrigger }) => {
    const [currentYearPDF, setCurrentYearPDF] = useState('');
    const [previousYearPDF, setPreviousYearPDF] = useState('');
    const [currentYearNumPages, setCurrentYearNumPages] = useState(null);
    const [previousYearNumPages, setPreviousYearNumPages] = useState(null);
    const [currentYearLoading, setCurrentYearLoading] = useState(true);
    const [previousYearLoading, setPreviousYearLoading] = useState(true);

    const currentYearRef = useRef(null);
    const previousYearRef = useRef(null);
    const pageRefs = useRef({});

    useEffect(() => {
        if (productId) {
            setCurrentYearLoading(true);
            setPreviousYearLoading(true);
            fetchPDFUrl(productId, false).then(url => {
                setCurrentYearPDF(url);
            });
            fetchPDFUrl(productId, true).then(url => {
                setPreviousYearPDF(url);
            });
        }
    }, [productId]);

    useEffect(() => {
        const syncScroll = (source, target) => {
            target.scrollTop = source.scrollTop;
        };

        const currentYearDiv = currentYearRef.current;
        const previousYearDiv = previousYearRef.current;

        const handleCurrentYearScroll = () => syncScroll(currentYearDiv, previousYearDiv);
        const handlePreviousYearScroll = () => syncScroll(previousYearDiv, currentYearDiv);

        if (currentYearDiv && previousYearDiv) {
            if (isLinked) {
                currentYearDiv.addEventListener('scroll', handleCurrentYearScroll);
                previousYearDiv.addEventListener('scroll', handlePreviousYearScroll);
            } else {
                currentYearDiv.removeEventListener('scroll', handleCurrentYearScroll);
                previousYearDiv.removeEventListener('scroll', handlePreviousYearScroll);
            }
            return () => {
                currentYearDiv.removeEventListener('scroll', handleCurrentYearScroll);
                previousYearDiv.removeEventListener('scroll', handlePreviousYearScroll);
            };
        }
    }, [currentYearPDF, previousYearPDF, isLinked]);

    const onDocumentLoadSuccess = (setNumPages, setLoading) => ({ numPages }) => {
        setNumPages(numPages);
        setLoading(false);
    };

    useEffect(() => {
        if (pageNumber !== null) {
            if (currentYearRef.current && pageRefs.current[pageNumber]) {
                currentYearRef.current.scrollTo({
                    top: pageRefs.current[pageNumber].offsetTop,
                    behavior: 'smooth'
                });
            }
            if (previousYearRef.current && pageRefs.current[pageNumber]) {
                previousYearRef.current.scrollTo({
                    top: pageRefs.current[pageNumber].offsetTop,
                    behavior: 'smooth'
                });
            }
        }
    }, [scrollTrigger, pageNumber]);

    return (
        <PDFContainer id="pdf-viewer-container">
            <PDFViewerWrapper id="pdf-viewer-previous-year-wrapper"
                ref={previousYearRef}
            >
                {previousYearLoading && (
                    <LoadingOverlay id="pdf-viewer-previous-year-loading-overlay">
                        Loading Previous Year PDF...
                    </LoadingOverlay>
                )}
                {previousYearPDF && (
                    <Document
                        id="pdf-viewer-previous-year-document"
                        file={previousYearPDF}
                        onLoadSuccess={onDocumentLoadSuccess(setPreviousYearNumPages, setPreviousYearLoading)}
                    >
                        {Array.from(new Array(previousYearNumPages), (el, index) => (
                            <div key={`page_${index + 1}`} ref={el => { pageRefs.current[index + 1] = el; }}>
                                <Page
                                    id={`pdf-viewer-previous-year-page-${index + 1}`}
                                    pageNumber={index + 1}
                                    scale={zoomLevel}
                                />
                            </div>
                        ))}
                    </Document>
                )}
            </PDFViewerWrapper>
            <PDFViewerWrapper id="pdf-viewer-current-year-wrapper" ref={currentYearRef}>
                {currentYearLoading && (
                    <LoadingOverlay id="pdf-viewer-current-year-loading-overlay">
                        Loading Current Year PDF...
                    </LoadingOverlay>
                )}
                {currentYearPDF && (
                    <Document
                        id="pdf-viewer-current-year-document"
                        file={currentYearPDF}
                        onLoadSuccess={onDocumentLoadSuccess(setCurrentYearNumPages, setCurrentYearLoading)}
                    >
                        {Array.from(new Array(currentYearNumPages), (el, index) => (
                            <div key={`page_${index + 1}`} ref={el => { pageRefs.current[index + 1] = el; }}>
                                <Page
                                    id={`pdf-viewer-current-year-page-${index + 1}`}
                                    pageNumber={index + 1}
                                    scale={zoomLevel}
                                />
                            </div>
                        ))}
                    </Document>
                )}
            </PDFViewerWrapper>
        </PDFContainer>
    );
};

const PDFContainer = styled.div`  
    display: flex;  
    justify-content: space-between;  
    width: 66.2%;  
    margin: 68px 0px 12px -4px;  
    height: calc(100vh - 286px);  
    gap: 8px;  
    position: relative;  
`;

const PDFViewerWrapper = styled.div`  
    width: 100%;  
    border: 1px solid #E5E5E6;  
    flex-grow: 1;  
    overflow: auto;  
    position: relative;  
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
`;

const LoadingOverlay = styled.div`  
    position: absolute;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    background: rgba(255, 255, 255, 1);  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    font-size: 16px;  
    color: #000;  
    z-index: 100;  
`;

export default PDFViewerSection;
