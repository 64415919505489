import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import NavBar from './navbar';
import SideNav from './sidenav';
import SepyCategory from './SepyCategory';
import { fetchDeltaData } from '../../services/endpoints';

function SelectionPage() {
  const location = useLocation();
  const { productId } = location.state || {};
  const reviewRef = useRef(null);
  const approvedRef = useRef(null);
  const [data, setData] = useState({ needsReview: [], approvedChanges: [] });
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const [activeSection, setActiveSection] = useState('needsReview');
  const [checkboxStates, setCheckboxStates] = useState({});

  const resetCheckboxStates = () => {
    setCheckboxStates({});
    setIsAnyCheckboxChecked(false);
    localStorage.setItem('checkboxStates', JSON.stringify({}));
    localStorage.setItem('selectedPrefixes', JSON.stringify([]));
  };

  const handleSetActiveSection = (section) => {
    localStorage.setItem('activeSection', section);
    setActiveSection(section);
  };

  const fetchData = async (productId) => {
    if (!productId) return;
    localStorage.setItem('productId', JSON.stringify(productId));
    fetchDeltaData(productId, (responseData) => {
      if (!responseData.data || !Array.isArray(responseData.data)) {
        setData({ product_id: productId, needsReview: [], approvedChanges: [] });
        return;
      }

      const needsReview = {};
      const approvedChanges = {};

      responseData.data.forEach(item => {
        const groupKey = item.sepy_prefix;
        if (item.is_reviewed) {
          if (!approvedChanges[groupKey]) {
            approvedChanges[groupKey] = { prefix: groupKey, items: [] };
          }
          approvedChanges[groupKey].items.push(item);
        } else {
          if (!needsReview[groupKey]) {
            needsReview[groupKey] = { prefix: groupKey, items: [] };
          }
          needsReview[groupKey].items.push(item);
        }
      });

      setData({
        product_id: productId,
        needsReview: Object.values(needsReview),
        approvedChanges: Object.values(approvedChanges)
      });
    });
  };

  const needsReviewCount = data.needsReview.reduce((total, group) => total + group.items.length, 0);
  const approvedChangesCount = data.approvedChanges.reduce((total, group) => total + group.items.length, 0);
  const isSubmitEnabled = data.needsReview.length === 0 && data.approvedChanges.length > 0;

  useEffect(() => {
    const storedActiveSection = localStorage.getItem('activeSection');
    if (storedActiveSection) {
      setActiveSection(storedActiveSection);
    }
    fetchData(productId);
  }, [productId, location.key]);

  if (!productId) {
    return <Container>No product selected</Container>;
  }

  return (
    <Container id="selection-index-page-container">
      <NavBar
        id="selection-index-page-navbar"
        isAnyCheckboxChecked={isAnyCheckboxChecked}
        isSubmitEnabled={isSubmitEnabled}
        productId={data.product_id}
      />
      <MainContent id="selection-index-main-content">
        <SideNav
          id="selection-index-sidenav"
          reviewCount={needsReviewCount}
          approvedCount={approvedChangesCount}
          activeSection={activeSection}
          setActiveSection={handleSetActiveSection}
          resetCheckboxStates={resetCheckboxStates}
        />
        <ContentArea id="selection-index-content-area">
          <SepyCategory
            id="selection-index-sepy-category"
            data={data}
            reviewRef={reviewRef}
            approvedRef={approvedRef}
            setIsAnyCheckboxChecked={setIsAnyCheckboxChecked}
            activeSection={activeSection}
            checkboxStates={checkboxStates}
            setCheckboxStates={setCheckboxStates}
            reviewCount={needsReviewCount}
            approvedCount={approvedChangesCount}
          />
        </ContentArea>
      </MainContent>
    </Container>
  );
}

const Container = styled.div`  
  display: flex;  
  width: 100%;  
`;

const MainContent = styled.div`  
  display: flex;  
  flex-direction: row;  
  width: 100%;  
`;

const ContentArea = styled.div`  
  margin-left: 242px;
  width: calc(100% - 242px);
`;

export default SelectionPage;