import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import BackArrow from '../../assets/backarrow.svg';
import { useNavigate } from 'react-router-dom';
import ShowIcon from '../../assets/showicon.svg'
import HideIcon from '../../assets/hideicon.svg';
import CustomDropdown from './CustomDropdown';
import RelatedIDsModal from './RelatedIDsModal';
import AsterickRed from '../../assets/redasterick.svg';
import ReportIcon from '../../assets/alerticon.svg';
import { approveChangesAPI, discardChangesAPI, fetchRelatedServices } from '../../services/endpoints';
import ReportModal from "./ReportModal";

const RightContent = ({ productId, filteredSepyData, uniquePrefixes, setPageNumber, setIsLinked, setScrollTrigger, seseRules }) => {
  const navigate = useNavigate();
  const [showPrefixes, setShowPrefixes] = useState(false);
  const [userSelectedRules, setUserSelectedRules] = useState({});
  const [relatedServices, setRelatedServices] = useState([]);

  const toggleShow = () => {
    setShowPrefixes(!showPrefixes);
  };

  const handleDateClick = (pageNumbers) => {
    if (pageNumbers && pageNumbers.length > 0) {
      setPageNumber(pageNumbers[0]);
      setScrollTrigger(prev => prev + 1);
      setIsLinked(false);
    }
  };

  const [isEdited, setIsEdited] = useState(false);
  const [selectedServiceDesc, setSelectedServiceDesc] = useState('');

  const [showReportModal, setShowReportModal] = useState(false);

  const handleSupportClick = () => {
    setShowReportModal(true);
  };

  const closePopup = () => {
    setShowReportModal(false);
  };

  const discardChanges = async () => {
    localStorage.removeItem('checkboxStates');
    const dataToSend = filteredSepyData.map(item => ({
      sepy_prefix: item.sepy_prefix,
      service_id: item.service_id,
      sepy_effective_date: item.sepy_effective_date,
      service_cost_rule: item.new_service_cost_rule_overridden || item.new_service_cost_rule,
      service_cost_rule_id: item.new_service_cost_rule_id_overridden || item.new_service_cost_rule_id
    }));

    let selectedPrefixes = JSON.parse(localStorage.getItem('selectedPrefixes')) || [];
    selectedPrefixes = selectedPrefixes.map(prefixObj => {
      if (filteredSepyData.some(item => item.sepy_prefix === prefixObj.prefix)) {
        return { ...prefixObj, section: 'needsReview' };
      }
      return prefixObj;
    });
    localStorage.setItem('selectedPrefixes', JSON.stringify(selectedPrefixes));

    try {
      await discardChangesAPI(productId, dataToSend);
      console.log("Changes discarded successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error discarding changes:", error);
    }
  };

  const discardEdits = () => {
    discardChanges();
    setIsEdited(false);
  };

  const isDiscardButtonActive = filteredSepyData.some(item => {
    const userSelectedRule = userSelectedRules[item.id];
    if (userSelectedRule) {
      const [selectedRuleId, selectedRuleDesc] = userSelectedRule.split(' - ');
      return selectedRuleId !== item.new_service_cost_rule_id_overridden || selectedRuleDesc !== item.new_service_cost_rule_overridden;
    }
    return item.new_service_cost_rule_id_overridden;
  });

  const handleRuleChange = (itemId, option) => {
    setUserSelectedRules(prev => ({
      ...prev,
      [itemId]: option
    }));
    setIsEdited(true);
  };

  const RuleDropdown = ({ itemId, selectedRule, onChange, isDisabled, currentRule }) => {
    const options = seseRules.map(rule => `${rule.sese_rule} - ${rule.sese_desc}`);
    const displayedRule = userSelectedRules[itemId] || selectedRule;
    return (
      <CustomDropdown
        id="right-dropdown-custom"
        options={options}
        selectedOption={displayedRule}
        onOptionSelect={(option) => onChange(itemId, option)}
        isDisabled={isDisabled}
      />
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (serviceDesc, origServiceRelId) => {
    if (!origServiceRelId || origServiceRelId.trim() === "") {
      setRelatedServices([]);
      setSelectedServiceDesc(serviceDesc);
      setIsModalOpen(true);
    } else {
      fetchRelatedServices(origServiceRelId, (response) => {
        if (response.status_code === 0) {
          setRelatedServices(response.data);
        } else {
          console.error('Failed to fetch related services:', response);
          setRelatedServices([]);
        }
      });
      setSelectedServiceDesc(serviceDesc);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isApproved, setIsApproved] = useState(false);

  const approveChanges = async () => {
    setIsApproved(true);
    setIsDropdownDisabled(true);
    setIsEdited(false);
    localStorage.removeItem('checkboxStates');

    const dataToSend = filteredSepyData.map(item => {
      const selectedRule = userSelectedRules[item.id]
        ? userSelectedRules[item.id].split(' - ')[0]
        : item.new_service_cost_rule_id_overridden || item.new_service_cost_rule_id;
      const selectedDesc = userSelectedRules[item.id]
        ? userSelectedRules[item.id].split(' - ')[1]
        : item.new_service_cost_rule_overridden || item.new_service_cost_rule;

      return {
        sepy_prefix: item.sepy_prefix,
        service_id: item.service_id,
        sepy_effective_date: item.sepy_effective_date,
        service_cost_rule: selectedDesc,
        service_cost_rule_id: selectedRule
      };
    });

    let selectedPrefixes = JSON.parse(localStorage.getItem('selectedPrefixes')) || [];
    selectedPrefixes = selectedPrefixes.map(prefixObj => {
      if (filteredSepyData.some(item => item.sepy_prefix === prefixObj.prefix)) {
        return { ...prefixObj, section: 'approvedChanges' };
      }
      return prefixObj;
    });
    localStorage.setItem('selectedPrefixes', JSON.stringify(selectedPrefixes));

    try {
      await approveChangesAPI(productId, dataToSend);
      console.log("Changes approved successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error approving changes:", error);
    }
  };

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  const handleModifyChanges = () => {
    setIsDropdownDisabled(false);
    setIsApproved(false);
  };

  useEffect(() => {
    if (filteredSepyData.length > 0 && !isEdited) {
      const allApproved = filteredSepyData.every(item => {
        return item.is_reviewed;
      });
      if (allApproved) {
        setIsDropdownDisabled(true);
        setIsApproved(true);
      }
    }
  }, [filteredSepyData, isEdited]);

  const extractYear = (date) => new Date(date).getFullYear();
  const isSingleCard = filteredSepyData.length === 1;

  return (
    <RightSideContainer id="analysis-rightside-container">
      <BackButton id="analysis-rightside-back-button" onClick={() => navigate('/queue/selection', { state: { productId } })}>
        <ImageContainer id="analysis-rightside-back-image-container">
          <img id="analysis-rightside-back-arrow" src={BackArrow} alt="Back Arrow" />
        </ImageContainer>
        <span>Back</span>
      </BackButton>
      <ContentContainer id="analysis-rightside-content-container">
        <MainPrefix id="analysis-rightside-main-prefix">
          <ViewingContainer id="analysis-rightside-viewing-container">
            <ViewingText id="analysis-rightside-viewing-text">Viewing {uniquePrefixes.length} SEPY prefixes</ViewingText>
            <Divider id="analysis-rightside-divider" />
            <ShowHideButton id="analysis-rightside-show-hide-button" onClick={toggleShow}>
              <ImageContainer id="analysis-rightside-show-hide-image-container">
                <img
                  id="analysis-rightside-show-hide-icon"
                  className={showPrefixes ? "hide-icon" : "show-icon"}
                  src={showPrefixes ? HideIcon : ShowIcon}
                  alt={showPrefixes ? "Hide" : "Show"}
                />
              </ImageContainer>
              <span>{showPrefixes ? "Hide" : "Show"}</span>
            </ShowHideButton>
          </ViewingContainer>
          <PrefixesContainer id="analysis-rightside-prefixes-container" showPrefixes={showPrefixes}>
            {uniquePrefixes.map(prefix => (
              <PrefixChip id={`analysis-rightside-prefix-${prefix}`} key={prefix}>
                {prefix}
              </PrefixChip>
            ))}
          </PrefixesContainer>
        </MainPrefix>
        {filteredSepyData.map(item => {
          const yearStart = extractYear(item.sepy_effective_date) - 1;
          const yearEnd = extractYear(item.sepy_effective_date);
          return (
            <Card id={`analysis-rightside-card-${item.id}`} key={item.id}>
              <CardHeader id={`analysis-rightside-card-header-${item.id}`}>
                <ServiceDesc>{item.service_desc}</ServiceDesc>
                {' '}
                <SepyPrefix>({item.sepy_prefix})</SepyPrefix>
              </CardHeader>
              <CardContent id={`analysis-rightside-card-content-${item.id}`}>
                <PlanContainer id={`analysis-rightside-plan-container-${item.id}`}>
                  <PlanDetail id={`analysis-rightside-plan-detail-start-${item.id}`}>
                    <PlanTitle id={`analysis-rightside-plan-title-start-${item.id}`} as="span" onClick={() => handleDateClick(item.page)}>{yearStart} plan</PlanTitle>
                    <PlanRule id={`analysis-rightside-plan-rule-start-${item.id}`}>{item.service_cost_rule_id}</PlanRule>
                    <PlanDescription id={`analysis-rightside-plan-description-start-${item.id}`}>{item.service_cost_rule}</PlanDescription>
                  </PlanDetail>
                  <VerticalDivider id={`analysis-rightside-vertical-divider-${item.id}`} />
                  <PlanDetail id={`analysis-rightside-plan-detail-end-${item.id}`}>
                    <PlanTitle id={`analysis-rightside-plan-title-end-${item.id}`} as="span" onClick={() => handleDateClick(item.page)}>{yearEnd} plan</PlanTitle>
                    <PlanRuleNew id={`analysis-rightside-plan-rule-end-${item.id}`}>
                      {item.new_service_cost_rule_id_overridden || item.new_service_cost_rule_id}
                    </PlanRuleNew>
                    <PlanDescription id={`analysis-rightside-plan-description-end-${item.id}`}>
                      {item.new_service_cost_rule_overridden || item.new_service_cost_rule}
                    </PlanDescription>
                  </PlanDetail>
                </PlanContainer>
                <ServiceRule id={`analysis-rightside-service-rule-${item.id}`}>
                  <ServiceRuleTitle id={`analysis-rightside-service-rule-title-${item.id}`}>
                    {yearEnd} service rule
                    <AsterickIcon id={`analysis-rightside-asterisk-icon-${item.id}`} src={AsterickRed} alt="Asterisk Red Icon" />
                  </ServiceRuleTitle>
                  <RuleDropdown
                    itemId={item.id}
                    selectedRule={item.new_service_cost_rule_id_overridden || item.new_service_cost_rule_id
                      ? `${item.new_service_cost_rule_id_overridden || item.new_service_cost_rule_id} - ${item.new_service_cost_rule_overridden || item.new_service_cost_rule}`
                      : ""}
                    onChange={handleRuleChange}
                    isDisabled={isDropdownDisabled}
                    currentRule={item.new_service_cost_rule_id_overridden || item.new_service_cost_rule_id
                      ? `${item.new_service_cost_rule_id_overridden || item.new_service_cost_rule_id} - ${item.new_service_cost_rule_overridden || item.new_service_cost_rule}`
                      : ""}
                  />
                </ServiceRule>
                <ViewRelatedLinkContainer id={`analysis-rightside-view-related-link-container-${item.id}`}>
                  <ViewRelatedLink
                    id={`analysis-rightside-view-related-link-${item.id}`}
                    onClick={() => openModal(item.service_desc, item.orig_service_rel_id)}
                  >
                    View related SESE IDs
                  </ViewRelatedLink>
                </ViewRelatedLinkContainer>
              </CardContent>
            </Card>
          );
        })}
        <DividerLine id="analysis-rightside-divider-line" />
        <ReportIssueContainer id="analysis-rightside-report-issue-container">
          <ReportIssueLink id="analysis-rightside-report-issue-link" onClick={handleSupportClick}>
            <ReportIconImg id="analysis-rightside-report-icon-img" src={ReportIcon} alt="Report Icon" />
            <ReportText id="analysis-rightside-report-text">Report an issue</ReportText>
          </ReportIssueLink>
          {showReportModal && <ReportModal onClose={closePopup} />}
        </ReportIssueContainer>
      </ContentContainer>
      {isApproved ? (
        <ButtonContainer id="analysis-rightside-button-container-approved">
          <ModifyButton id="analysis-rightside-modify-button" onClick={handleModifyChanges}>
            {isSingleCard ? 'Modify change' : 'Modify changes'}
          </ModifyButton>
        </ButtonContainer>
      ) : (
        <ButtonContainer id="analysis-rightside-button-container-not-approved">
          <ApproveButton id="analysis-rightside-approve-button" onClick={approveChanges}>
            {isSingleCard ? 'Approve change' : 'Approve changes'}
          </ApproveButton>
          <DiscardButton
            id="analysis-rightside-discard-button"
            isActive={isDiscardButtonActive}
            onClick={isDiscardButtonActive ? discardEdits : null}
          >
            {isSingleCard ? 'Discard edit' : 'Discard edits'}
          </DiscardButton>
        </ButtonContainer>
      )}
      <RelatedIDsModal
        id="analysis-rightside-related-ids-modal"
        isOpen={isModalOpen}
        onClose={closeModal}
        serviceDesc={selectedServiceDesc}
        relatedServices={relatedServices}
      />
    </RightSideContainer>
  );
};

const RightSideContainer = styled.div`  
  background-color: #FFFFFF;  
  width: 33.33%;  
  position: fixed;  
  right: 0;  
  top: 167px;  
  bottom: 44px;  
  padding: 24px;  
  border: 1px solid #E5E5E6;  
  border-bottom: none;  
  border-right: none;  
  display: flex;  
  flex-direction: column;  
  justify-content: space-between;  
`;

const ContentContainer = styled.div`  
  flex-grow: 1;  
  overflow: auto;
  margin-bottom: 36px;
`;

const BackButton = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  padding: 0px;  
  gap: 4px;  
  width: 62px;  
  height: 24px;  
  cursor: pointer;  
  border-bottom: 2px solid transparent;
  margin-bottom: 8px;
  
  img {  
    width: 12.98px;  
    height: 12.64px;  
  }  
  
  span {  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 16px;  
    line-height: 20px;  
    color: #0C55B8;  
  }  

  &:hover {
    border-bottom: 2px solid #0C55B8;
  }
`;

const ImageContainer = styled.div`  
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ViewingContainer = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center; 
  gap: 2px;  
  width: 432px;  
  height: 36px; 
`;

const ViewingText = styled.div`  
  display: flex;  
  align-items: center;  
  padding-right: 8px;
  width: 165px;  
  height: 18px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 400;  
  font-size: 14px;  
  line-height: 18px;  
  color: #323334;  
`;

const ShowHideButton = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  padding: 8px; 
  gap: 4px;  
  height: 36px;  
  background: #FFFFFF;  
  border-radius: 4px;  
  cursor: pointer;  
  
  .show-icon {  
    width: 17.88px;
    height: 12.5px;
  }  
  
  .hide-icon {  
    width: 17.96px;
    height: 15.79px;
  }  
  
  span {  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 400;  
    font-size: 14px;  
    line-height: 18px;  
    color: #0C55B8;  
  }  
  
  &:hover {  
    background-color: #F3F3F3;  
  }  
`;

const Divider = styled.div`  
  width: 1px;
  height: 21px;
  background-color: #d8d8d8;
`;

const MainPrefix = styled.div`
  margin-bottom: 16px;
`;

const PrefixesContainer = styled.div`  
  display: flex;  
  flex-direction: row;  
  gap: 8px;  
  margin-top: 8px;  
  overflow: hidden;  
  animation: ${({ showPrefixes }) => (showPrefixes ? expand : collapse)} 0.5s ease-in-out;  
  max-height: ${({ showPrefixes }) => (showPrefixes ? '500px' : '0')};  
  opacity: ${({ showPrefixes }) => (showPrefixes ? '1' : '0')};  
`;

const PrefixChip = styled.div`  
  background: #F4F4F4;  
  border: 1px solid #7D7F81;  
  border-radius: 100px;  
  padding: 4px 8px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-size: 14px;  
  line-height: 18px;  
  color: #323334;  
`;

const ButtonContainer = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  justify-content: center; 
  padding: 0px;  
  gap: 24px;
  margin-bottom: 20px;
`;

const Button = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: flex-start;  
  padding: 0px;  
  width: 204px;  
  height: 40px;  
  border-radius: 24px;  
  flex-grow: 1;
`;

const ApproveButton = styled(Button)`  
  justify-content: center;  
  align-items: center;  
  background: #002677;  
  color: #FFFFFF;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  cursor: pointer; 
  
  &:hover {
    background-color: #001D5B;
  }
`;

const DiscardButton = styled(Button)`  
  justify-content: center;  
  align-items: center;  
  background: ${props => (props.isActive ? 'FFF' : '#F3F3F3')};  
  color: ${props => (props.isActive ? '#002677' : '#929496')};  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  border: ${props => (props.isActive ? '1px solid #002677' : 'none')};
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  cursor: ${props => (props.isActive ? 'pointer' : 'default')};  
    
  &:hover {  
    background-color: ${props => (props.isActive ? '#F3F3F3' : 'none')};  
    color: ${props => (props.isActive ? '#002677' : '#929496')};
    border-color: ${props => (props.isActive ? '#001D5B' : 'none')};
  }  
`;

const ModifyButton = styled(Button)`  
  justify-content: center;  
  align-items: center;  
  background: #002677;  
  color: #FFFFFF;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  cursor: pointer;  
    
  &:hover {  
    background-color: #001D5B;  
  }  
`;

const Card = styled.div`  
  box-sizing: border-box;  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 16px 16px 24px;  
  gap: 24px;  
  width: 100%;  
  background: #FFFFFF;  
  border: 1px solid #D8D8D8;  
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);  
  border-radius: 8px;  
  margin-bottom: 24px;
`;

const CardHeader = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-size: 20.25px;  
  line-height: 24px;  
  color: #002677;  
  width: 100%;  
`;

const ServiceDesc = styled.span`  
  font-weight: 700;  
`;

const SepyPrefix = styled.span`  
  font-weight: 400;  
`;

const CardContent = styled.div`  
  display: flex;  
  flex-direction: column;  
  justify-content: space-between;  
  width: 100%;  
`;

const PlanContainer = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: start;
  justify-content: space-between;  
  width: 100%;  
  gap: 16px; 
`;

const PlanDetail = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  width: 48%;  
  min-height: 85px;
`;

const PlanTitle = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;  
  color: #323334;  
  margin-bottom: 16px;  
  position: relative;  
  width: 73px;  
  height: 20px;  
  cursor: default;  
  
  &:after {  
    content: '';  
    display: block;  
    width: 100%;  
    height: 1px;  
    border-bottom: 1px dashed #0C55B8;  
    position: absolute;  
    bottom: 0;  
  }  
  
  &:hover {  
    color: #002677;  
  
    &:after {  
      border-bottom: 1px solid #002677;  
    }  
  }  
`;

const PlanRule = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  color: #323334;  
  padding: 2px;
`;

const PlanRuleNew = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  color: #323334;  
  border-radius: 2px;
  padding: 2px;
  background-color: rgba(245, 183, 0, 0.25);
`;

const PlanDescription = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 14px;  
  line-height: 18px;  
  color: #323334;  
  min-height: 18px;
`;

const ServiceRule = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  width: 100%;  
  margin-top: 24px;  
  position: relative;  
`;

const ServiceRuleTitle = styled.div`  
  display: flex;  
  align-items: center;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  color: #323334;  
  margin-bottom: 8px;  
`;

const AsterickIcon = styled.img`  
  width: 12px;  
  height: 12px;  
  margin-left: 4px;
  margin-bottom: 6px;  
`;

const ViewRelatedLinkContainer = styled.div`  
  display: flex;  
  justify-content: flex-start; 
  width: auto;
  margin-top: 24px;  
`;

const ViewRelatedLink = styled.div`  
  display: inline-block;
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 14px;  
  line-height: 18px;  
  color: #0C55B8;  
  cursor: pointer;  
    
  &:hover {  
    text-decoration: underline;  
  }  
`;

const VerticalDivider = styled.div`  
  width: 1px;  
  height: 85px;  
  border: 1px solid #D8D8D8;  
`;

const DividerLine = styled.div`  
  width: 100%;  
  height: 1px;  
  border-top: 1px solid #D8D8D8;  
  margin: 24px 0;
`;

const ReportIssueContainer = styled.div`  
  display: flex;  
  flex-direction: column;  
  justify-content: center;  
  align-items: flex-start;  
  padding: 0px 0px 24px;  
  gap: 16px;  
  width: 100%;  
  height: 45px;  
`;

const ReportIssueLink = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  gap: 8px;  
  cursor: pointer;  
`;

const ReportIconImg = styled.img`  
  margin-top: 3px; 
`;

const ReportText = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 14px;  
  line-height: 18px;  
  color: #0C55B8;  
  &:hover {  
    text-decoration: underline;  
  }  
`;

const expand = keyframes`  
  from {  
    max-height: 0;  
    opacity: 0;  
    overflow: hidden;  
  }  
  to {  
    max-height: 500px;  
    opacity: 1;  
    overflow: hidden;  
  }  
`;

const collapse = keyframes`  
  from {  
    max-height: 500px;  
    opacity: 1;  
    overflow: hidden;  
  }  
  to {  
    max-height: 0;  
    opacity: 0;  
    overflow: hidden;  
  }  
`;

export default RightContent;