import React from 'react';
import styled from 'styled-components';

function Footer() {
  return (
    <Root id="footer-root">  
      <Separator id="footer-separator" />  
      <FooterContent id="footer-content">  
        <LeftContent id="footer-left-content">  
          <Copyright id="footer-copyright">  
            © 2024 Optum Inc.  
          </Copyright>  
          <Divider id="footer-divider-1">|</Divider>  
          <Copyright id="footer-all-rights">  
            All Rights Reserved  
          </Copyright>  
        </LeftContent>  
        <RightContent id="footer-right-content">  
          <Copyright id="footer-privacy">  
            Privacy  
          </Copyright>  
          <Divider id="footer-divider-2">|</Divider>  
          <Copyright id="footer-terms">  
            Terms of Use  
          </Copyright>  
        </RightContent>  
      </FooterContent>  
    </Root>
  );
}

const Root = styled.div`  
    display: flex;  
    flex-direction: column;  
    background-color: white;  
    min-width: 900px;  
    overflow: auto;  
    background: transparent;  
    padding: 10px 0px 16px 0px;  
    transition: left 0.25s;
    box-sizing: border-box;  
    z-index: 50;
`;

const Separator = styled.div`  
    width: 98%;
    height: 1px;  
    background-color: #4B4D4F;
    margin: 0 auto 10px auto;
    box-sizing: border-box;  
`;

const FooterContent = styled.div`  
    display: flex;  
    flex-direction: row;  
    justify-content: space-between;  
    align-items: center;  
    width: 98%;
    margin: 0 auto; 
`;

const LeftContent = styled.div`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
`;

const RightContent = styled.div`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
`;

const Copyright = styled.div`  
    color: #4B4D4F;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-size: 14px;  
    line-height: 18px;  
    font-weight: 400;  
`;

const Divider = styled.div`  
    display: flex;  
    flex-direction: row;  
    justify-content: flex-start;  
    align-items: center;  
    color: #4B4D4F;  
    padding: 0 8px 0 8px;  
    font-feature-settings: 'clig' off, 'liga' off;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-size: 14px;  
    font-style: normal;  
    font-weight: 400;  
    line-height: 18px;  
`;

export default Footer;