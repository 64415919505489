import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import CloseButton from '../../assets/reportclose.svg';

const ReportModal = ({ onClose }) => {
    const modalRef = useRef();
    const [message, setMessage] = useState('');
    const [focused, setFocused] = useState(false);
    const maxCharacters = 250;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        const disableScroll = () => {
            document.body.style.overflow = 'hidden';
        };

        const enableScroll = () => {
            document.body.style.overflow = '';
        };

        document.addEventListener('mousedown', handleClickOutside);
        disableScroll();
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            enableScroll();
        };
    }, [onClose]);

    return ReactDOM.createPortal(
        <Overlay>
            <Popup ref={modalRef}>
                <PopupContent>
                    <Header>
                        <Title>Report an Issue</Title>
                        <StyledCloseButton onClick={onClose}>
                            <img src={CloseButton} alt="Close" />
                        </StyledCloseButton>
                    </Header>
                    <SubHeader>
                        We're sorry to hear you're having trouble. Please describe the issue in detail and we'll follow up if we need more information.
                    </SubHeader>
                    <TextAreaContainer>
                        <TextArea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            maxLength={maxCharacters}
                            focused={focused}
                            hasText={!!message}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                        />
                        <CharacterCount>{maxCharacters - message.length} characters remaining</CharacterCount>
                    </TextAreaContainer>
                    <ButtonContainer>
                        <CancelButton onClick={onClose}>Cancel</CancelButton>
                        <SendButton disabled={!message}>Send message</SendButton>
                    </ButtonContainer>
                </PopupContent>
            </Popup>
        </Overlay>,
        document.body
    );
};

const Overlay = styled.div`  
  position: fixed;  
  top: 0;  
  left: 0;  
  width: 100%;  
  height: 100%;  
  background-color: rgba(75, 77, 79, 0.6);  
  display: flex;  
  align-items: center;  
  justify-content: center;  
  z-index: 999;  
`;

const Popup = styled.div`  
  background-color: white;  
  padding: 16px 24px 24px 24px;  
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);  
  border-radius: 4px;  
  width: 724px;
  max-width: 784px;  
  display: flex;  
  flex-direction: column;  
`;

const PopupContent = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  width: 100%;  
`;

const Header = styled.div`  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  width: 100%;  
  padding-top: 8px;  
  margin-bottom: 8px;
`;

const Title = styled.h2`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 25.63px;  
  color: #002677;  
  margin: 0;  
`;

const StyledCloseButton = styled.button`  
  background: none;  
  border: none;  
  cursor: pointer;  
`;

const SubHeader = styled.p`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;
  sans-serif;  
  font-weight: 400;  
  font-size: 16px;  
  color: #323334;  
  margin: 0 0 16px 0;  
  padding-bottom: 8px;
`;

const TextAreaContainer = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  width: 100%;  
  margin-bottom: 32px;  
`;

const TextArea = styled.textarea`  
  width: 100%;  
  height: 160px;  
  padding: 12px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-size: 16px;  
  border: 1px solid ${({ focused, hasText }) => (focused || hasText ? '#0C55B8' : '#4B4D4F')};  
  border-radius: 4px;  
  resize: none;  

  &:focus {  
    outline: none;  
    border-color: #0C55B8;  
  }

  &:hover {
    outline: none;  
    border-color: #0C55B8;
  }
`;

const CharacterCount = styled.div`  
  width: 100%;  
  text-align: right;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-size: 14px;  
  color: #4B4D4F;  
  margin-top: 4px;  
`;

const ButtonContainer = styled.div`  
  display: flex;  
  justify-content: flex-end;  
  width: 100%;  
`;

const CancelButton = styled.button`  
  background: #FFFFFF;  
  border: 1px solid #002677;  
  border-radius: 24px;  
  padding: 10px 24px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  color: #002677;  
  cursor: pointer;  
  margin-right: 16px;  

  &:hover {
    background-color: #F3F3F3;
    color: #001D5B;
    border-color: #001D5B;
  }
`;

const SendButton = styled.button`  
  background: #F3F3F3;  
  border-radius: 24px;  
  padding: 10px 24px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  color: #929496;  
  cursor: pointer;  
  border: 1px solid transparent;  
  ${({ disabled }) =>
        !disabled &&
        `  
      background: #002677;  
      color: #FFFFFF; 
      
      &:hover {
        background-color: #001D5B;
      }
  `}  
  
  &:disabled {  
    cursor: default;  
  }  
`;

export default ReportModal;  