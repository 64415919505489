import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import ExpandMore from '../../assets/expandmore.svg';
import ExpandLess from '../../assets/expandless.svg';
import MagnifyGlass from '../../assets/searchiconbulk.svg';
import CheckMark from '../../assets/checkmarksmall.svg';
import CloseButton from '../../assets/closeicon.svg';

const BulkEditModal = ({ isOpen, onClose, selectedCount, selectedRows, owners }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const modalRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isDropdownOpen) {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setIsDropdownOpen(false);
                }
            } else {
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    onClose();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen, onClose]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setSelectedOwner('');
            setSearchTerm('');
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    const uniqueOwners = [...new Set(selectedRows.map((row) => row.owner))];
    const currentOwner = uniqueOwners.length > 1 ? 'Mixed' : uniqueOwners[0];

    const handleDropdownToggle = () => {
        if (isDropdownOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setIsDropdownOpen(false);
                setIsClosing(false);
            }, 200);
        } else {
            setIsDropdownOpen(true);
        }
    };

    const handleOptionSelect = (owner) => {
        setSelectedOwner(owner);
        setIsClosing(true);
        setTimeout(() => {
            setIsDropdownOpen(false);
            setIsClosing(false);
        }, 200);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredOwners = owners.filter((owner) =>
        owner.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <ModalOverlay id="modal-overlay">
            <ModalContent id="modal-content" ref={modalRef}>
                <ModalHeader id="modal-header">
                    <ModalTitle id="modal-title">Bulk modify</ModalTitle>
                    <CloseIcon id="modal-close-icon" src={CloseButton} alt="Close" onClick={onClose} />
                </ModalHeader>
                <ModalBody id="modal-body">
                    <p id="modal-description">
                        For the {selectedCount} checked items, modify the owner associated with them.
                    </p>
                    <ModalTable id="modal-table">
                        <ModalTableRow id="modal-table-row-header">
                            <ModalTableHeader id="modal-table-header-current-owner">Current owner</ModalTableHeader>
                            <ModalTableHeader id="modal-table-header-new-owner" large>New owner</ModalTableHeader>
                        </ModalTableRow>
                        <ModalTableRow id="modal-table-row-owners">
                            <ModalTableCell id="modal-table-cell-current-owner">{currentOwner}</ModalTableCell>
                            <ModalTableCell id="modal-table-cell-new-owner" large>
                                <DropdownContainer id="modal-dropdown-container" ref={dropdownRef}>
                                    <DropdownHeader id="modal-dropdown-header" isDropdownOpen={isDropdownOpen} onClick={handleDropdownToggle}>
                                        {isDropdownOpen ? (
                                            <>
                                                <SearchIcon id="modal-search-icon" src={MagnifyGlass} alt="Search" />
                                                <SearchInput
                                                    id="modal-search-input"
                                                    type="text"
                                                    placeholder="Search"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                    autoFocus
                                                />
                                                <ExpandLessIcon id="modal-expand-less-icon" src={ExpandLess} onClick={handleDropdownToggle} />
                                            </>
                                        ) : (
                                            <>
                                                {selectedOwner || 'Select owner'}
                                                <ExpandIcon id="modal-expand-icon" src={ExpandMore} isDropdownOpen={isDropdownOpen} />
                                            </>
                                        )}
                                    </DropdownHeader>
                                    {isDropdownOpen && (
                                        <DropdownList id="modal-dropdown-list" isClosing={isClosing}>
                                            <Divider />
                                            {filteredOwners.map((owner, index) => (
                                                <DropdownItem
                                                    id={`modal-dropdown-item-${index}`}
                                                    key={index}
                                                    onClick={() => handleOptionSelect(owner)}
                                                >
                                                    {owner}
                                                    {selectedOwner === owner && <CheckIcon id="modal-check-icon" src={CheckMark} alt="Selected" />}
                                                </DropdownItem>
                                            ))}
                                        </DropdownList>
                                    )}
                                </DropdownContainer>
                            </ModalTableCell>
                        </ModalTableRow>
                    </ModalTable>
                    <ModalFooter id="modal-footer">
                        <CancelButton id="modal-cancel-button" onClick={onClose}>Cancel</CancelButton>
                        <ModalButton id="modal-apply-button" primary disabled={!selectedOwner}>Apply</ModalButton>
                    </ModalFooter>
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

const DropdownContainer = styled.div`  
  position: relative;  
  width: 100%;  
`;

const DropdownHeader = styled.div`  
    display: flex;  
    align-items: center;  
    padding: 8px;  
    width: 100%;  
    height: 40px;  
    min-height: 38px;  
    background: #FFFFFF;  
    border: 1px solid #7D7F81;  
    border-radius: 4px;  
    cursor: pointer;  
    ${({ isDropdownOpen }) => isDropdownOpen && `  
        border-color: #0C55B8;  
        border-bottom: none;  
        border-top-left-radius: 4px;  
        border-top-right-radius: 4px;  
        border-bottom-left-radius: 0;  
        border-bottom-right-radius: 0;  
    `}  
    position: relative;

    &:hover {
        border-color: #0C55B8;
    }
`;

const SearchIcon = styled.img`  
  width: 18px;  
  height: 18px;  
  margin-right: 4px;  
`;

const ExpandIcon = styled.img`  
    width: 11.15px;  
    height: 6.55px;  
    margin-left: auto;  
    transform: ${({ isDropdownOpen }) => (isDropdownOpen ? 'none' : 'matrix(-1, 0, 0, 1, 0, 0)')};  
    position: absolute;
    right: 8px;  
    top: 50%; 
    transform: translateY(-50%) ${({ isDropdownOpen }) => (isDropdownOpen ? 'none' : 'matrix(-1, 0, 0, 1, 0, 0)')};  
`;

const ExpandLessIcon = styled.img`  
    width: 11.15px;  
    height: 6.55px;  
    position: absolute;  
    right: 8px;
    top: 50%;
    transform: translateY(-50%);  
    cursor: pointer;  
    z-index: 11;
`;

const DropdownList = styled.ul`  
    position: absolute;  
    top: 100%;  
    left: 0;  
    right: 0;  
    background: #FFFFFF;  
    border-bottom: 1px solid #0C55B8;  
    border-radius: 0 0 4px 4px;  
    z-index: 10;  
    max-height: 200px;  
    overflow-y: auto;  
    padding: 0;  
    margin: 0;  
    list-style: none;  
    width: 100%;  
    background-color: #FFFFFF;  
    ${({ isClosing }) => isClosing && css`  
        animation: ${dissolveOut} 0.5s forwards;  
    `};  
  
    & > li:first-child {  
        border-top: 1px solid #CBCCCD;  
    }  
`;

const SearchInput = styled.input`  
    width: calc(100% - 16px);  
    border: none;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-size: 14px;  
    line-height: 18px;  
    &:focus {  
        outline: none;  
    }  
    &::placeholder {
        color: #6D6F70;
    }
`;

const DropdownItem = styled.li`  
    display: flex;  
    justify-content: space-between;  
    flex-direction: row;  
    align-items: center;  
    padding: 8px;  
    width: 100%;  
    height: 38px;  
    min-height: 38px;  
    background: #FFFFFF;  
    border-width: 0px 1px;  
    border-style: solid;  
    border-color: #0C55B8;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 400;  
    font-size: 14px;  
    line-height: 18px;  
    color: #323334;  
    cursor: pointer;  
    &:hover {  
        background: #F3F3F3;  
    }  
    &:last-child {  
        border-bottom-left-radius: 4px;  
        border-bottom-right-radius: 4px;  
    }  
`;

const CheckIcon = styled.img`  
    width: 10.33px;  
    height: 7.48px;
`;

const ModalOverlay = styled.div`  
    position: fixed;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    background: rgba(75, 77, 79, 0.6);  
    display: flex;  
    justify-content: center;  
    align-items: center; 
    z-index: 60;
`;

const ModalContent = styled.div`  
    background: white;  
    padding: 20px;  
    border-radius: 4px;  
    width: 602px;  
    height: 274px;  
    max-width: 100%;  
    padding: 16px 24px 16px 24px;
`;

const ModalHeader = styled.div`  
    position: relative;
    margin-bottom: 8px;  
    display: flex;  
    justify-content: space-between;  
    align-items: center;  
`;

const ModalTitle = styled.h2`  
    color: #002677;  
    font-size: 25.63px; 
    width: 554px; 
    margin: 0;  
    font-weight: 700;
    line-height: 32px;
`;

const ModalBody = styled.div`  
    p {  
        color: #323334;
        font-size: 16px;
        margin-bottom: 24px;
        font-weight: 400;
        line-height: 20px;
    }  
`;

const ModalTable = styled.div`  
    display: flex;  
    flex-direction: column;  
    align-items: flex-start;  
    padding: 0px;  
    width: 554px;  
    height: 88px;  
`;

const ModalTableRow = styled.div`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
    padding: 0px;  
    width: 554px;  
    height: 40px;  
`;

const ModalTableHeader = styled.div`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
    padding: 2px 8px;  
    background: #F3F3F3;  
    border: 1px solid #CBCCCD;  
    border-right: none;
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 14px;  
    line-height: 18px;  
    color: #323334;  
    width: ${props => props.large ? '351px' : '203px'};  
    height: 40px;  
  
    &:last-child {  
        border-right: 1px solid #CBCCCD;
    }  
`;

const ModalTableCell = styled.div`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
    padding: 4px 8px !important;  
    background: #FAFAFA;  
    border: 1px solid #CBCCCD;  
    border-right: none;
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 400;  
    font-size: 14px;  
    line-height: 18px;  
    color: #323334;  
    width: ${props => props.large ? '351px' : '203px'};  
    height: 48px;  
  
    &:last-child {  
        border-right: 1px solid #CBCCCD;
    }  
`;

const ModalFooter = styled.div`  
    display: flex;  
    justify-content: flex-end;
    padding-top: 28px;
    gap: 8px;  
`;

const ModalButton = styled.button`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
    justify-content: center;  
    padding: 10px 24px;  
    width: 93px;  
    height: 40px;  
    background: ${({ primary, disabled }) => (disabled ? '#F3F3F3' : primary ? '#002677' : '#ccc')};  
    border: ${({ primary, disabled }) => (disabled ? 'none' : primary ? 'none' : '1px solid #002677')};  
    border-radius: 24px;  
    color: ${({ disabled }) => (disabled ? '#929496' : '#FFFFFF')};  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 16px;  
    line-height: 20px;  
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};  
    &:hover {  
        background: ${({ primary, disabled }) => (disabled ? '#F3F3F3' : primary ? '#001F4D' : '#f0f0f0')};  
    }  
`;

const CancelButton = styled.button`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
    justify-content: center;  
    padding: 10px 24px;  
    width: 101px;  
    height: 40px;  
    background: #FFFFFF;  
    border: 1px solid #002677;  
    border-radius: 24px;  
    color: #002677;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 16px;  
    line-height: 20px;  
    cursor: pointer;  
  
    &:hover {  
        background: #F3F3F3;  
        color: #001D5B;
        border-color: #001D5B;
    }  
`;

const CloseIcon = styled.img`  
    position: absolute;  
    top: 10px;  
    right: 0px;  
    cursor: pointer;  
    width: 11.67px;  
    height: 11.67px;  
`;

const dissolveOut = keyframes`  
    from {  
        opacity: 1;  
    }  
    to {  
        opacity: 0;  
    }  
`;

const Divider = styled.div`  
  position: relative;  
  height: 0.8px;  
  background-color: #CBCCCD;  
  
  &::before,  
  &::after {  
    content: '';  
    position: absolute;  
    width: 1px;  
    height: 1px;  
    background-color: #0C55B8;  
  }  
  
  &::before {  
    left: 0;  
  }  
  
  &::after {  
    right: 0;  
  }  
`;

export default BulkEditModal;