import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

function SideNav({ activeSection, setActiveSection }) {
  const handleNavClick = (section) => {
    setActiveSection(section);
    localStorage.setItem('activeSection', section);
  };

  return (
    <>
      <GlobalStyle id="support-sidenav-globalstyles" />
      <NavContainer id="support-sidenav-container">
        <TitleSection id="support-sidenav-title-section">
          <Title id="support-sidenav-title-text">Support center</Title>
        </TitleSection>
        <NavItem
          id="support-sidenav-nav-item-contact"
          active={activeSection === 'contactUs'}
          onClick={() => handleNavClick('contactUs')}
        >
          <NavText
            id="support-sidenav-nav-text-contact"
            active={activeSection === 'contactUs'}
          >
            Contact us
          </NavText>
        </NavItem>
        <NavItem
          id="support-sidenav-nav-item-feedback"
          active={activeSection === 'leaveFeedback'}
          onClick={() => handleNavClick('leaveFeedback')}
        >
          <NavText
            id="support-sidenav-nav-text-feedback"
            active={activeSection === 'leaveFeedback'}
          >
            Leave feedback
          </NavText>
        </NavItem>
      </NavContainer>
    </>
  );
}

const GlobalStyle = createGlobalStyle`  
  * {  
    margin: 0;  
    padding: 0;  
    box-sizing: border-box;  
  }  
  body {  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
  }  
`;

const NavContainer = styled.div`  
  position: fixed;  
  top: 164px;  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 0px 0 0 12px; 
  width: 242px;  
`;

const TitleSection = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 0px;   
  margin-bottom: 24px;  
`;

const Title = styled.h1`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 700;  
  font-size: 26px;  
  line-height: 31.2px;  
  color: #323334;  
`;

const NavItem = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  padding: 0px 24px 0px 16px;  
  width: 242px;  
  height: 48px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 22.4px;  
  color: #0C55B8;  
  position: relative; 
  left: 24px;
  
  &::before {  
    content: '';  
    position: absolute;  
    left: 0px;  
    width: 4px;  
    height: 100%;  
    background-color: ${props => (props.active ? '#FF612B' : 'transparent')};  
    border-radius: 2px;  
  }  
`;

const NavText = styled.span`  
  position: relative;  
  cursor: pointer;  
  color: ${props => (props.active ? '#002677' : '#0C55B8')};  
  
  &::after {  
    content: '';  
    position: absolute;  
    left: 0;  
    bottom: -1px;  
    height: 2px;  
    width: 0;  
    background-color: ${props => (props.active ? '#002677' : '#0C55B8')};  
  }  
  
  &:hover::after {  
    width: 100%;  
  }  
  
  ${props => props.active && `  
    &::after {  
      width: 0;
    }  
  
    &:hover::after {  
      width: 100%;  
      background-color: #002677;
    }  
  `}  
`;

export default SideNav;