import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import SupportContactIcon from '../../assets/SupportContact.svg';
import SupportFeedbackIcon from '../../assets/SupportFeedback.svg';
import { useNavigate } from "react-router-dom";

const SupportLandingPage = () => {
  const navigate = useNavigate();

  const handleNavigate = (section) => {
    localStorage.setItem('activeSection', section);
    navigate("/support/contact-and-feedback", { state: { activeSection: section } });
  };

  return (
    <>
      <GlobalStyle id="support-landing-global-style" />
      <Container id="support-landing-container">
        <Banner id="support-landing-banner">
          <BannerText id="support-landing-banner-text">How can we help?</BannerText>
        </Banner>
        <PageLinks id="support-landing-page-links">
          <StyledLink id="support-landing-contact-link" onClick={() => handleNavigate('contactUs')}>
            <Icon id="support-landing-contact-icon" src={SupportContactIcon} alt="Contact us" />
            <LinkTitle id="support-landing-contact-title">Contact us</LinkTitle>
            <LinkDescription id="support-landing-contact-description">
              Send a message to our support team
            </LinkDescription>
          </StyledLink>
          <StyledLink id="support-landing-feedback-link" onClick={() => handleNavigate('leaveFeedback')}>
            <Icon id="support-landing-feedback-icon" src={SupportFeedbackIcon} alt="Leave feedback" />
            <LinkTitle id="support-landing-feedback-title">Leave feedback</LinkTitle>
            <LinkDescription id="support-landing-feedback-description">
              Leave feedback to help us improve the application
            </LinkDescription>
          </StyledLink>
        </PageLinks>
      </Container>
    </>
  );
};

const GlobalStyle = createGlobalStyle`  
  * {  
    margin: 0;  
    padding: 0;  
    box-sizing: border-box;  
  }  
  body {  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
  }  
`;

const Container = styled.div`  
    display: flex;  
    flex-direction: column;  
    align-items: center;
    width: 100%;  
    position: relative;  
`;

const Banner = styled.div`  
  display: flex;  
  flex-direction: row;  
  justify-content: center;  
  align-items: center;  
  padding: 96px 0;  
  position: absolute;  
  width: calc(100% + 40px);
  left: -20px;   
  top: -20px; 
  background: rgba(217, 246, 250, 0.75);  
`;

const BannerText = styled.h1`   
  font-family: "Enterprise Sans VF", Helvetica, Arial;   
  font-style: normal;  
  font-weight: 700;  
  font-size: 32px;  
  line-height: 40px;  
  color: #002677;  
`;

const PageLinks = styled.div`  
  display: flex;  
  flex-direction: row;  
  justify-content: center;  
  align-items: flex-start;  
  padding: 0px;  
  gap: 64px;  
  position: absolute;  
  top: 292px;  
`;

const LinkTitle = styled.h2`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;   
  font-style: normal;  
  font-weight: 700;  
  font-size: 26px;  
  line-height: 32px;  
  text-align: center;  
  color: #0C55B8;  
  margin: 8px 0;  
`;

const StyledLink = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: center;  
  max-width: 202px;  
  cursor: pointer;  
  
  &:hover ${LinkTitle} {  
    text-decoration: underline;  
  }  
`;

const Icon = styled.img`  
  width: 80px;  
  height: 80px;  
`;

const LinkDescription = styled.p`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;   
  font-style: normal;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;  
  text-align: center;  
  color: #323334;  
`;

export default SupportLandingPage;