import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ArrowDown from '../../assets/arrowdownlight.svg';
import ArrowUp from '../../assets/arrowuplight.svg';
import CheckIcon from '../../assets/checkicon.svg';

const SimpleDropdown = ({ options, selectedOption, onOptionSelect, isDisabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleOpenDropdown = () => {
        if (!isDisabled) {
            setIsOpen(true);
        }
    };

    const handleCloseDropdown = () => {
        if (!isDisabled) {
            setIsOpen(false);
        }
    };

    const handleToggleDropdown = () => {
        if (isOpen) {
            handleCloseDropdown();
        } else {
            handleOpenDropdown();
        }
    };

    const handleOptionClick = (option) => {
        if (!isDisabled) {
            onOptionSelect(option);
            handleCloseDropdown();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <DropdownContainer ref={dropdownRef} id="support-dropdown-container">
            <SelectedOption
                id="support-dropdown-selected-option"
                isOpen={isOpen}
                isDisabled={isDisabled}
                onClick={handleToggleDropdown}
            >
                <DropdownInput
                    id="support-dropdown-input"
                    type="text"
                    value={selectedOption || "Select"}
                    readOnly
                    isOpen={isOpen}
                    isDisabled={isDisabled}
                    isPlaceholder={!selectedOption}
                />
                <DropdownToggle id="support-dropdown-toggle">
                    <img src={isOpen ? ArrowUp : ArrowDown} alt="Toggle Dropdown" />
                </DropdownToggle>
            </SelectedOption>
            {isOpen && <Divider id="support-dropdown-divider" />}
            {isOpen && (
                <OptionsList id="support-dropdown-options-list">
                    {options.map((option, index) => (
                        <OptionItem
                            id={`support-dropdown-option-item-${index}`}
                            key={index}
                            onClick={() => handleOptionClick(option)}
                        >
                            <span id={`support-dropdown-option-text-${index}`}>{option}</span>
                            {option === selectedOption && (
                                <img id={`support-dropdown-option-check-${index}`} src={CheckIcon} alt="Selected" />
                            )}
                        </OptionItem>
                    ))}
                </OptionsList>
            )}
        </DropdownContainer>
    );
};

const DropdownContainer = styled.div`  
  position: relative;  
  width: 100%;  
`;

const SelectedOption = styled.div`  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  padding: 8px;  
  border: 1px solid ${({ isOpen }) => (isOpen ? '#0C55B8' : '#323334')};  
  border-radius: 5px 5px ${({ isOpen }) => (isOpen ? '0 0' : '5px 5px')};  
  background-color: #FFFFFF;  
  cursor: pointer;  
  
  &:hover {  
    border: 1px solid #0C55B8;  
  }  
  
  img {  
    cursor: pointer;  
  }  
`;

const DropdownToggle = styled.div`  
  display: flex;  
  align-items: center;  
  cursor: pointer;  
`;

const DropdownInput = styled.input`  
    border: none;  
    outline: none;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 400;  
    font-size: 16px;  
    line-height: 20px;  
    color: ${({ isPlaceholder }) => (isPlaceholder ? '#6D6F70' : '#323334')};  
    width: 100%;  
    cursor: pointer;  
    background-color: #FFFFFF;  
  
    &::placeholder {  
        color: #6D6F70;  
    }  
`;

const OptionsList = styled.div`  
  position: absolute;  
  top: 40px;  
  left: 0;  
  right: 0;  
  background-color: #FFFFFF;  
  border: 1px solid #0C55B8;  
  border-top: none;  
  border-radius: 0 0 5px 5px;  
  max-height: 220px;  
  overflow-y: auto;  
  z-index: 1000;  
`;

const OptionItem = styled.div`  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  padding: 12px 8px;  
  cursor: pointer;  
  
  &:hover {  
    background-color: #F3F3F3;  
  }  
  
  span {  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 400;  
    font-size: 16px;  
    color: #323334;  
  }  
`;

const Divider = styled.div`  
  height: 0.5px; 
  top: -1.5px; 
  background-color: #CBCCCD;  
  width: calc(100% - 1.6px);  
  position: relative;
  z-index: 1001;
  box-sizing: border-box;  
  margin: 0 0.8px 0 0.8px;
`;

export default SimpleDropdown;  