import React from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import styled from "styled-components";

const Root = styled.div`
  box-sizing: border-box;
  display: flex;
`;

function LogoPanel() {
  return (
    <Root id="logopanel-root">
      <Logo id="logopanel-logo" />
    </Root>
  );
}

export default LogoPanel;