import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ArrowDown from '../../assets/selectionpagearrowdown.svg';
import CheckBox from '../../assets/checkbox.svg';
import CheckBoxChecked from '../../assets/checkboxselected.svg';
import ForwardArrow from '../../assets/forwardarrow.svg';
import ArrowUp from '../../assets/selectionpagearrowup.svg';

const Accordion = ({ serviceId, changeCount, sepyCategories, expanded, toggleSection, onCheckboxChange, isReviewReqd }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef(null);
  const navigate = useNavigate();

  const handleCheckboxClick = (e) => {  
    e.stopPropagation();
    const newCheckedState = !isChecked;  
    setIsChecked(newCheckedState);  
    onCheckboxChange(serviceId, isReviewReqd, newCheckedState);  

    const storedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || {};  
    storedCheckboxStates[`${serviceId}_${isReviewReqd}`] = newCheckedState;  
    localStorage.setItem('checkboxStates', JSON.stringify(storedCheckboxStates));

    let selectedPrefixes = JSON.parse(localStorage.getItem('selectedPrefixes')) || [];
    if (newCheckedState) {
      if (!selectedPrefixes.includes(serviceId)) {
        selectedPrefixes.push(serviceId);
      }
    } else {
      selectedPrefixes = selectedPrefixes.filter(prefix => prefix !== serviceId);
    }
    localStorage.setItem('selectedPrefixes', JSON.stringify(selectedPrefixes));
  };

  const handleCategoryClick = (serviceId, category) => {
    const selectedPrefixes = [serviceId];
    const productId = JSON.parse(localStorage.getItem('productId'));
    localStorage.setItem('storedPrefix', serviceId);
    navigate('/queue/selection/analysis', { state: { selectedPrefixes, serviceDesc: category, productId } });
  };
  useEffect(() => {  
    const storedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || {};  
    if (storedCheckboxStates[`${serviceId}_${isReviewReqd}`] !== undefined) {  
      setIsChecked(storedCheckboxStates[`${serviceId}_${isReviewReqd}`]);  
    }  

    if (expanded) {  
      setMaxHeight(contentRef.current.scrollHeight);  
    } else {  
      setMaxHeight(0);  
    }  
  }, [expanded, serviceId, isReviewReqd]);

  return (
    <AccordionContainer id="selection-accordion-container">
      <TopSection onClick={toggleSection} expanded={expanded} id="selection-accordion-top-section">
        <HeaderContent id="selection-accordion-header-content">
          <Checkbox onClick={handleCheckboxClick} id="selection-accordion-checkbox">
            <CheckboxIcon src={isChecked ? CheckBoxChecked : CheckBox} alt="Checkbox" id="selection-accordion-checkbox-icon" />
          </Checkbox>
          <ServiceId id="selection-accordion-service-id">{serviceId}</ServiceId>
          <Divider id="selection-accordion-divider" />
          <ChangeCount id="selection-accordion-change-count">{changeCount} changes</ChangeCount>
          <ExpandMore id="selection-accordion-expand-more">
            <ExpandIcon src={expanded ? ArrowUp : ArrowDown} alt="Expand" id="selection-accordion-expand-icon" />
          </ExpandMore>
        </HeaderContent>
      </TopSection>
      <ExpandedContent ref={contentRef} style={{ maxHeight: `${maxHeight}px` }} id="selection-accordion-expanded-content">
        {sepyCategories.map((category, index) => (
          <React.Fragment key={index}>
            <Category onClick={() => handleCategoryClick(serviceId, category)} id={`selection-accordion-category-${index}`}>
              <CategoryContent id={`selection-accordion-category-content-${index}`}>
                {category}
                <ArrowIcon src={ForwardArrow} alt="Forward" id={`selection-accordion-arrow-icon-${index}`} />
              </CategoryContent>
            </Category>
            {index < sepyCategories.length - 1 && <DividerLine id={`selection-accordion-divider-line-${index}`} />}
          </React.Fragment>
        ))}
      </ExpandedContent>
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`  
  box-sizing: border-box;  
  display: flex;  
  flex-direction: column;  
  justify-content: flex-start;  
  align-items: flex-start;  
  width: 100%;  
  background: #FFFFFF;  
  border: 1px solid #CBCCCD;  
  border-radius: 4px;  
`;

const TopSection = styled.div`  
  justify-content: center;  
  align-items: flex-end;  
  padding: 16px 24px;  
  width: 100%; 
  border-radius: 4px;  
  background: ${({ expanded }) => (expanded ? '#F3F3F3' : '#FFFFFF')};  
  cursor: pointer;  
  
  &:hover {  
    background: #F3F3F3;  
  }  
`;

const HeaderContent = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  padding: 0px;  
  gap: 16px;  
  width: 100%;  
  height: 36px; 
`;

const Checkbox = styled.div`  
    display: flex;  
    flex-direction: row;  
    align-items: flex-start;  
    padding: 0px;  
    cursor: pointer;  
    user-select: none;  
    pointer-events: auto;  
    border: 1px solid transparent;
    border-radius: 2px;

    &:hover {
      border-color: #0C55B8;
    }
`;

const CheckboxIcon = styled.img`  
  width: 26px;  
  height: 26px;  
  padding: 3.25px;  
`;

const ServiceId = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  color: #323334;  
`;

const Divider = styled.div`  
  width: 0;  
  height: 17px;  
  border-left: 1px solid #CBCCCD;   
`;

const ChangeCount = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;  
  text-align: right;  
  color: #323334;  
`;

const ExpandMore = styled.div`  
  margin-left: auto;
  width: 36px;  
  height: 36px;  
`;

const ExpandIcon = styled.img`  
  width: 100%;  
  height: 100%;  
`;

const ExpandedContent = styled.div`  
    display: flex;  
    flex-direction: column;  
    align-items: flex-start;  
    padding: 0 24px 0 47px;  
    width: 100%;  
    overflow: hidden;  
    transition: max-height 0.3s ease-out;  
`;

const Category = styled.div`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
    padding: 16px 0 16px 19px;  
    width: 100%;
`;

const CategoryContent = styled.div`   
    align-items: center;
    display: flex;  
    flex-direction: row;
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 700;  
    font-size: 16px;  
    color: #0C55B8;  
    cursor: pointer; 
    gap: 4px;
    border-bottom: 2px solid transparent;

    &:hover {
      border-color: #0C55B8;
    }
`;

const ArrowIcon = styled.img`  
    width: 24px;  
    height: 24px;  
`;

const DividerLine = styled.div`  
    width: 100%;  
    height: 0px;  
    border-top: 1px solid #CBCCCD;  
`;

export default Accordion;