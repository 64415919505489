import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import RightArrow from '../../assets/rightarrow.svg';
import SubmitModal from './SubmitModal';

function NavBar({ isAnyCheckboxChecked, isSubmitEnabled, productId }) {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSubmitClick = () => {
        if (isSubmitEnabled) {
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleViewClick = () => {
        if (isAnyCheckboxChecked) {
            const selectedPrefixes = JSON.parse(localStorage.getItem('selectedPrefixes')) || [];
            navigate('/queue/selection/analysis', { state: { productId, selectedPrefixes } });
        }
    };

    return (
        <>
            <GlobalStyle />
            <NavBarContainer id="selection-navbar-container">
                <NavLeft id="selection-navbar-nav-left">
                    <Breadcrumbs id="selection-navbar-breadcrumbs">
                        <BreadcrumbItem id="selection-navbar-breadcrumb-home" onClick={() => navigate('/queue')}>
                            Home
                        </BreadcrumbItem>
                        <BreadcrumbSeparator id="selection-navbar-breadcrumb-separator-2">
                            <img src={RightArrow} alt="Right Arrow" id="selection-navbar-breadcrumb-separator-img" />
                        </BreadcrumbSeparator>
                        <BreadcrumbCurrent id="selection-navbar-breadcrumb-current">{productId}</BreadcrumbCurrent>
                    </Breadcrumbs>
                </NavLeft>
                <NavRight id="selection-navbar-nav-right">
                    <ViewButton
                        id="selection-navbar-view-button"
                        active={isAnyCheckboxChecked}
                        onClick={handleViewClick}
                    >
                        View selected
                    </ViewButton>
                    <SubmitButton id="selection-navbar-submit-button" active={isSubmitEnabled} onClick={handleSubmitClick}>
                        Submit plan
                    </SubmitButton>
                </NavRight>
            </NavBarContainer>
            {isModalOpen && <SubmitModal isOpen={isModalOpen} onClose={handleCloseModal} productId={productId} />}
        </>
    );
}

const GlobalStyle = createGlobalStyle`  
    * {  
        margin: 0;  
        padding: 0;  
        box-sizing: border-box;  
    }  
`;

const NavBarContainer = styled.div`  
    display: flex; 
    position: fixed; 
    flex-direction: row;  
    justify-content: space-between;  
    align-items: center;  
    padding: 0 32px;  
    background: #FFFFFF;  
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);  
    width: 100%;  
    height: 56px;    
    top: 111px;  
    left: 0;  
    box-sizing: border-box;  
    z-index: 10;
`;

const NavLeft = styled.div`  
    display: flex;  
    align-items: center;  
`;

const Breadcrumbs = styled.div`  
    display: flex;  
    align-items: center;  
    gap: 8px;  
`;

const BreadcrumbItem = styled.span`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 700;  
    font-size: 14px;  
    color: #0C55B8;  
    text-decoration: underline;  
    cursor: pointer;  
`;

const BreadcrumbSeparator = styled.span`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 700;  
    font-size: 14px;  
    color: #4B4D4F;  
`;

const BreadcrumbCurrent = styled.span`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 400;  
    font-size: 14px;  
    color: #4B4D4F;  
`;

const NavRight = styled.div`  
    display: flex;  
    align-items: center;  
    gap: 16px;  
`;

const ViewButton = styled.button`  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  padding: 8px 24px;  
  background: ${({ active }) => (active ? '#FFFFFF' : '#F3F3F3')};  
  border-radius: 24px;  
  border: ${({ active }) => (active ? '1px solid #002677' : '1px solid transparent')};  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  color: ${({ active }) => (active ? '#002677' : '#929496')};
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};

  ${({ active }) =>
        active &&
        `  
    &:hover {  
        background: #F3F3F3; 
        border-color: #001D5B;
        color: #001D5B; 
    }  
`}
`;

const SubmitButton = styled.button`  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  padding: 8px 24px;  
  background: ${({ active }) => (active ? '#002677' : '#F3F3F3')};  
  border-radius: 24px;  
  border: ${({ active }) => (active ? '1px solid transparent' : '1px solid transparent')};  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  color: ${({ active }) => (active ? '#FFFFFF' : '#929496')};
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};

  ${({ active }) =>
        active &&
        `  
    &:hover {  
        background: #001D5B; 
    }  
`}
`;

export default NavBar;