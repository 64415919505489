import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function SideNav({ reviewCount, approvedCount, activeSection, setActiveSection, resetCheckboxStates }) {
  const initialActiveNav = localStorage.getItem('activeSection') || 'needsReview';
  const [activeNav, setActiveNav] = useState(initialActiveNav);

  const handleNavClick = (section) => {
    resetCheckboxStates();
    setActiveNav(section);
    setActiveSection(section);
  };

  useEffect(() => {
    setActiveNav(activeSection);
  }, [activeSection]);

  return (
    <NavContainer id="selection-sidenav-container">
      <NavItem id="selection-sidenav-nav-item-review" active={activeNav === 'needsReview'}>
        <NavText
          id="selection-sidenav-nav-text-review"
          onClick={() => handleNavClick('needsReview')}
          active={activeNav === 'needsReview'}
        >
          Needs review ({reviewCount})
        </NavText>
      </NavItem>
      <NavItem id="selection-sidenav-nav-item-approved" active={activeNav === 'approvedChanges'}>
        <NavText
          id="selection-sidenav-nav-text-approved"
          onClick={() => handleNavClick('approvedChanges')}
          active={activeNav === 'approvedChanges'}
        >
          Approved changes ({approvedCount})
        </NavText>
      </NavItem>
    </NavContainer>
  );
}

const NavContainer = styled.div`  
  position: fixed;  
  top: 167px;  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 32px 0 0 12px; 
  width: 242px;  
  height: calc(100% - 68px);  
`;

const NavItem = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  padding: 0px 24px 0px 16px;  
  width: 242px;  
  height: 48px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 22.4px;  
  color: #0C55B8;  
  position: relative; 
  
  &::before {  
    content: '';  
    position: absolute;  
    left: 0px;  
    width: 4px;  
    height: 100%;  
    background-color: ${props => (props.active ? '#FF612B' : 'transparent')};  
    border-radius: 2px;  
  }  
`;

const NavText = styled.span`  
  position: relative;  
  cursor: pointer;  
  color: ${props => (props.active ? '#002677' : '#0C55B8')};  
  
  &::after {  
    content: '';  
    position: absolute;  
    left: 0;  
    bottom: -1px;  
    height: 2px;  
    width: 0;  
    background-color: ${props => (props.active ? '#002677' : '#0C55B8')};  
  }  
  
  &:hover::after {  
    width: 100%;  
  }  
  
  ${props => props.active && `  
    &::after {  
      width: 0;
    }  
  
    &:hover::after {  
      width: 100%;  
      background-color: #002677;
    }  
  `}  
`;

export default SideNav;