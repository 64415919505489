import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from './navbar';
import styled from 'styled-components';
import RightContent from './RightContent';
import { fetchDeltaData, fetchSeseRules } from '../../services/endpoints';
import PDFViewerSection from './PDFViewerSection';

function AnalysisPage() {
  const location = useLocation();
  const { productId, serviceDesc } = location.state || {};
  const [sepyData, setSepyData] = useState([]);
  const [isLinked, setIsLinked] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(0.7);
  const [pageNumber, setPageNumber] = useState(null);
  const [scrollTrigger, setScrollTrigger] = useState(0);
  const [seseRules, setSeseRules] = useState([]);

  const handleLinkToggle = () => {
    setIsLinked(!isLinked);
  };

  const handleZoomIn = () => {
    setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 3.0));
  };

  const handleZoomOut = () => {
    setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 0.5));
  };

  useEffect(() => {
    if (productId) {
      fetchDeltaData(productId, (responseData) => {
        if (responseData && responseData.data) {
          setSepyData(responseData.data);
        }
      });
    }
  }, [productId]);

  const filteredSepyData = sepyData.filter(item => {  
    const selectedPrefixes = JSON.parse(localStorage.getItem('selectedPrefixes')) || [];  
    const sectionSelectedPrefixes = selectedPrefixes.map(prefixObj => prefixObj.prefix);  
    const storedPrefix = localStorage.getItem('storedPrefix'); 
     
    if (sectionSelectedPrefixes.length === 0) {  
      const result = (!serviceDesc || item.service_desc === serviceDesc) && (!storedPrefix || item.sepy_prefix === storedPrefix); 
      return result;  
    }  
    
    const prefixMatches = selectedPrefixes.some(prefixObj =>   
      prefixObj.prefix === item.sepy_prefix &&  
      prefixObj.section === (item.is_reviewed ? 'approvedChanges' : 'needsReview')  
    );  
    const result = prefixMatches && (!serviceDesc || item.service_desc === serviceDesc); 
    return result;  
  });  
  

  const uniquePrefixes = [...new Set(filteredSepyData.map(item => item.sepy_prefix))];

  useEffect(() => {
    if (productId) {
      fetchDeltaData(productId, (responseData) => {
        if (responseData && responseData.data) {
          setSepyData(responseData.data);
        }
      });
    }

    fetchSeseRules((data) => {
      if (data && data.data) {
        setSeseRules(data.data);
      } else {
        console.error('Failed to fetch SESE rules:', data);
      }
    });
  }, [productId]);

  return (
    <Container id="analysis-page-container">
      <NavBar
        id="analysis-page-navbar"
        productId={productId}
        uniquePrefixes={uniquePrefixes}
        isLinked={isLinked}
        handleLinkToggle={handleLinkToggle}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        zoomLevel={zoomLevel}
      />
      <PDFViewerSection id="analysis-page-left-content"
        productId={productId}
        isLinked={isLinked}
        zoomLevel={zoomLevel}
        pageNumber={pageNumber}
        scrollTrigger={scrollTrigger}
      />
      <RightContent
        id="analysis-page-right-content"
        productId={productId}
        sepyData={sepyData}
        setSepyData={setSepyData}
        filteredSepyData={filteredSepyData}
        uniquePrefixes={uniquePrefixes}
        setPageNumber={setPageNumber}
        setIsLinked={setIsLinked}
        setScrollTrigger={setScrollTrigger}
        seseRules={seseRules}
      />
    </Container>
  );
}

const Container = styled.div`  
    display: flex;  
    width: 100%;  
    padding: 20px;
    background-color: #F9F9F9;
`;

export default AnalysisPage;