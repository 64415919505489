import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PencilIcon from '../../assets/pencil.svg';
import CheckMarkIcon from '../../assets/checkmark.svg';
import Accordion from './accordion';

const SepyCategory = React.forwardRef(({ reviewRef, approvedRef, data, setIsAnyCheckboxChecked, activeSection, checkboxStates, setCheckboxStates, reviewCount, approvedCount }) => {
    const [expandedSections, setExpandedSections] = useState({
        needsReview: [],
        approvedChanges: [],
    });

    const toggleSection = (section, serviceId) => {
        setExpandedSections((prevState) => {
            const isExpanded = prevState[section].includes(serviceId);
            return {
                ...prevState,
                [section]: isExpanded
                    ? prevState[section].filter(id => id !== serviceId)
                    : [...prevState[section], serviceId]
            };
        });
    };

    const handleCheckboxChange = (serviceId, isReviewReqd, isChecked) => {
        setCheckboxStates((prevState) => {
            const newState = { ...prevState, [`${serviceId}_${isReviewReqd}`]: isChecked };
            const anyChecked = Object.values(newState).some(checked => checked);
            setIsAnyCheckboxChecked(anyChecked);

            let selectedPrefixes = JSON.parse(localStorage.getItem('selectedPrefixes')) || [];
            if (isChecked) {
                selectedPrefixes.push({ prefix: serviceId, section: isReviewReqd ? 'needsReview' : 'approvedChanges' });
            } else {
                selectedPrefixes = selectedPrefixes.filter(item => item.prefix !== serviceId);
            }
            localStorage.setItem('selectedPrefixes', JSON.stringify(selectedPrefixes));

            return newState;
        });
    };

    useEffect(() => {
        const storedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || {};
        setCheckboxStates(storedCheckboxStates);
        const anyChecked = Object.values(storedCheckboxStates).some(checked => checked);
        setIsAnyCheckboxChecked(anyChecked);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [data, setIsAnyCheckboxChecked, setCheckboxStates]);

    const [loading, setLoading] = useState(true);

    return (
        <Container id="selection-sepy-container">
            <MainContent id="selection-sepy-main-content">
                <HeaderContainer ref={reviewRef} id="selection-sepy-header-container">
                    <Heading id="selection-sepy-heading">{data.product_id}</Heading>
                    <Subtext id="selection-sepy-subtext">
                        Select multiple SEPY categories, one SEPY category, or a Service ID Description to view and approve new changes.
                    </Subtext>
                </HeaderContainer>
                {activeSection === 'needsReview' && (
                    <NeedsReviewSection id="selection-sepy-needs-review-section">
                        <SectionHeader id="selection-sepy-needs-review-header">
                            <SectionIcon src={PencilIcon} alt="Pencil" id="selection-sepy-needs-review-icon" />
                            <SectionTitle id="selection-sepy-needs-review-title">Needs review ({reviewCount})</SectionTitle>
                        </SectionHeader>
                        {loading ? (
                            <LoadingText id="selection-sepy-loading-text">Loading...</LoadingText>
                        ) : data.needsReview.length === 0 ? (
                            <NoChangesText id="selection-sepy-no-changes-text">All changes have been reviewed.</NoChangesText>
                        ) : (
                            data.needsReview.map((group, index) => (
                                <Accordion
                                    key={group.prefix}
                                    id={`selection-sepy-needs-review-accordion-${index}`}
                                    serviceId={group.prefix}
                                    isReviewReqd={true}
                                    changeCount={group.items.length}
                                    sepyCategories={group.items.map(item => item.service_desc)}
                                    expanded={expandedSections.needsReview.includes(group.prefix)}
                                    toggleSection={() => toggleSection('needsReview', group.prefix)}
                                    onCheckboxChange={handleCheckboxChange}
                                    checked={checkboxStates[group.prefix] || false}
                                />
                            ))
                        )}
                    </NeedsReviewSection>
                )}
                {activeSection === 'approvedChanges' && (
                    <ApprovedSection ref={approvedRef} id="selection-sepy-approved-section">
                        <SectionHeader id="selection-sepy-approved-header">
                            <SectionIcon src={CheckMarkIcon} alt="CheckMark" id="selection-sepy-approved-icon" />
                            <SectionTitle id="selection-sepy-approved-title">Approved changes ({approvedCount})</SectionTitle>
                        </SectionHeader>
                        {loading ? (
                            <LoadingText id="selection-sepy-loading-text">Loading...</LoadingText>
                        ) : (
                            data.approvedChanges.map((group, index) => (
                                <Accordion
                                    key={group.prefix}
                                    id={`selection-sepy-approved-accordion-${index}`}
                                    serviceId={group.prefix}
                                    changeCount={group.items.length}
                                    isReviewReqd={false}
                                    sepyCategories={group.items.map(item => item.service_desc)}
                                    expanded={expandedSections.approvedChanges.includes(group.prefix)}
                                    toggleSection={() => toggleSection('approvedChanges', group.prefix)}
                                    onCheckboxChange={handleCheckboxChange}
                                    checked={checkboxStates[group.prefix] || false}
                                />
                            ))
                        )}
                    </ApprovedSection>
                )}
            </MainContent>
        </Container>
    );
});

const Container = styled.div`  
    display: flex;  
    width: 100%;  
`;

const MainContent = styled.div`  
    display: flex;  
    flex-direction: column;  
    width: 100%;  
    padding: 68px 0 0 0;  
    margin-left: 24px;
    padding-right: 12px;  
`;

const HeaderContainer = styled.div`  
    display: flex;  
    flex-direction: column;  
    align-items: flex-start;  
    padding: 0px;  
    gap: 8px;  
    width: 100%;  
`;

const Heading = styled.div`  
    display: flex;  
    flex-direction: row;  
    align-items: center;  
    padding: 0px;  
    gap: 16px;  
    width: 100%;  
    height: 32px;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 26px;  
    line-height: 32px;  
    color: #002677;  
`;

const Subtext = styled.div`  
  width: 100%;  
  height: 22px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 22.4px;  
  color: #323334;  
`;

const NeedsReviewSection = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding-top: 32px;
  gap: 16px;  
  width: 100%;  
`;

const ApprovedSection = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding-top: 32px;
  gap: 16px;  
  width: 100%;  
`;

const SectionHeader = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  padding: 0;  
  gap: 8px;  
  width: 100%;  
  height: 24px;  
  margin-bottom: 8px;
`;

const SectionIcon = styled.img`  
  padding: 0;  
`;

const SectionTitle = styled.div`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 20.25px;  
  line-height: 24px;  
  color: #323334;  
`;

const NoChangesText = styled.div`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 400;  
    font-size: 16px;  
    line-height: 22.4px;  
    color: #323334;   
`;

const LoadingText = styled.div`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 400;  
    font-size: 16px;  
    line-height: 22.4px;  
    color: #323334;  
    margin-top: 16px;  
`;

export default SepyCategory;  
