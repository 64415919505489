import React from 'react';
import styled from 'styled-components';
import ReopenPlansIcon from '../../assets/reopened.svg';
import ModifyOwnersIcon from '../../assets/modifyowners.svg';

const PopupMenu = ({ onModifyOwnersClick, onReopenPlansClick }) => {
  return (
    <PopupContainer id="popup-container">
      <PopupItem id="popup-item-reopen-plans" onClick={onReopenPlansClick}>
        <IconReopen id="icon-reopen-plans" src={ReopenPlansIcon} alt="Reopen Plans" />
        <OptionText id="option-text-reopen-plans">Reopen plans</OptionText>
      </PopupItem>
      <PopupItem id="popup-item-modify-owners" onClick={onModifyOwnersClick}>
        <IconModify id="icon-modify-owners" src={ModifyOwnersIcon} alt="Modify Owners" />
        <OptionText id="option-text-modify-owners">Modify owners</OptionText>
      </PopupItem>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`  
  box-sizing: border-box;  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 8px;  
  gap: 4px;  
  position: absolute;  
  width: 216px;  
  background: #FFFFFF;  
  border: 1px solid #E5E5E6;  
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);  
  border-radius: 4px;  
  z-index: 54;
  top: 253px;
  left: 310px;
`;

const PopupItem = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  padding: 8px;  
  gap: 8px;  
  width: 200px;  
  height: 24px;  
  background: #FFFFFF;  
  border-radius: 4px;  
  cursor: pointer; 
  border: 1px solid transparent; 
  &:hover {  
    border: 1px solid #224AA0;
    background-color: #EEF4FF;  
  }  
`;

const IconModify = styled.img`  
  padding: 0;
`;

const IconReopen = styled.img`  
  padding: 0; 
`;

const OptionText = styled.span`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;  
  color: #323334;  
`;

export default PopupMenu;