import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ArrowDown from '../../assets/arrowdownlight.svg';
import ArrowUp from '../../assets/arrowuplight.svg';
import ArrowDownGrey from '../../assets/arrowdowngrey.svg';
import CheckIcon from '../../assets/checkicon.svg';
import CloseIcon from '../../assets/closeicon.svg';

const CustomDropdown = ({ options, selectedOption, onOptionSelect, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const selectedOptionRef = useRef(null);

  const handleOpenDropdown = () => {
    if (!isDisabled) {
      setIsOpen(true);
      setSearchTerm('');
    }
  };

  const handleCloseDropdown = () => {
    if (!isDisabled) {
      setIsOpen(false);
      setSearchTerm(selectedOption);
    }
  };

  const handleToggleDropdown = (e) => {
    if (isOpen) {
      handleCloseDropdown();
    } else {
      handleOpenDropdown();
    }
  };

  const handleOptionClick = (option) => {
    if (!isDisabled) {
      onOptionSelect(option);
      setSearchTerm(option);
      handleCloseDropdown();
    }
  };

  const handleSearchChange = (event) => {
    if (!isDisabled) {
      setSearchTerm(event.target.value);
    }
  };

  const handleClearSearch = (event) => {
    if (!isDisabled) {
      event.stopPropagation();
      setSearchTerm('');
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm(selectedOption);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedOption]);

  const sortedOptions = [...options].sort((a, b) => {
    const searchTermLower = searchTerm.toLowerCase();
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();

    const aIncludes = aLower.includes(searchTermLower);
    const bIncludes = bLower.includes(searchTermLower);

    if (aIncludes && !bIncludes) {
      return -1;
    }
    if (!aIncludes && bIncludes) {
      return 1;
    }
    return aLower.localeCompare(bLower);
  });

  const filteredOptions = sortedOptions.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (isOpen && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({ block: 'center' });
    }
  }, [isOpen]);

  return (
    <DropdownContainer ref={dropdownRef} id="analysis-dropdown-container">
      <SelectedOption
        id="analysis-dropdown-selected-option"
        isOpen={isOpen}
        isDisabled={isDisabled}
        onClick={handleToggleDropdown}
      >
        <DropdownInput
          id="analysis-dropdown-input"
          ref={inputRef}
          type="text"
          value={isOpen ? searchTerm : selectedOption}
          onChange={handleSearchChange}
          placeholder="Search for a service rule"
          readOnly={isDisabled}
          isOpen={isOpen}
          isDisabled={isDisabled}
          onClick={(e) => {
            e.stopPropagation();
            if (!isOpen) {
              handleOpenDropdown();
            }
          }}
        />
        {isOpen && searchTerm && !isDisabled && (
          <CloseIconWrapper
            id="analysis-dropdown-clear-icon-wrapper"
            onClick={(e) => {
              e.stopPropagation();
              handleClearSearch(e);
            }}
          >
            <img id="analysis-dropdown-clear-icon" src={CloseIcon} alt="Clear Search" />
          </CloseIconWrapper>
        )}
        <DropdownToggle id="analysis-dropdown-toggle">
          <img
            id="analysis-dropdown-toggle-icon"
            src={isDisabled ? ArrowDownGrey : (isOpen ? ArrowUp : ArrowDown)}
            alt="Toggle Dropdown"
          />
        </DropdownToggle>
      </SelectedOption>
      {isOpen && <Divider />}
      {isOpen && !isDisabled && (
        <OptionsList id="analysis-dropdown-options-list">

          <ScrollableContent>
            {filteredOptions.map((option, index) => (
              <OptionItem
                id={`analysis-dropdown-option-${index}`}
                key={index}
                ref={option === selectedOption ? selectedOptionRef : null}
                onClick={() => handleOptionClick(option)}
              >
                <span id={`analysis-dropdown-option-text-${index}`}>{option}</span>
                {option === selectedOption && (
                  <img id={`analysis-dropdown-option-selected-icon-${index}`} src={CheckIcon} alt="Selected" />
                )}
              </OptionItem>
            ))}
          </ScrollableContent>
        </OptionsList>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`  
  position: relative;  
  width: 100%;  
`;

const SelectedOption = styled.div`  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  padding: 8px;  
  border: 1px solid ${({ isOpen, isDisabled }) => (isDisabled ? '#929496' : isOpen ? '#0C55B8' : '#323334')};  
  border-radius: 5px 5px ${({ isOpen }) => (isOpen ? '0 0' : '5px 5px')}; 
  border-bottom: ${({ isOpen }) => (isOpen ? 'none' : '1px solid #929496')};
  background-color: ${({ isDisabled }) => (isDisabled ? '#F3F3F3' : '#FFFFFF')};  
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};  
    
  &:hover {  
    border: 1px solid ${({ isDisabled }) => (isDisabled ? '#929496' : '#0C55B8')};  
  }  
    
  img {  
    cursor: pointer;  
    filter: ${({ isDisabled }) => (isDisabled ? 'grayscale(100%)' : 'none')};  
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }  
`;

const DropdownToggle = styled.div`  
  display: flex;  
  align-items: center;  
  cursor: pointer;  
`;

const DropdownInput = styled.input`  
  border: none;  
  outline: none;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;
  color: ${({ isDisabled }) => (isDisabled ? '#929496' : '#323334')};  
  width: 100%;  
  cursor: ${(props) => (props.isDisabled ? 'default' : props.isOpen ? 'text' : 'pointer')};
  background-color: ${({ isDisabled }) => (isDisabled ? '#F3F3F3' : '#FFFFFF')};  
  
  &::placeholder {  
    color: ${({ isDisabled }) => (isDisabled ? '#F3F3F3' : '#6D6F70')};  
  }  
`;

const CloseIconWrapper = styled.div`  
  display: flex;  
  align-items: center;  
  padding: 0 8px;  
  cursor: pointer;  
  
  img {  
    width: 9.86px;  
    height: 9.86px;  
  }  
`;

const OptionsList = styled.div`  
  position: absolute;  
  top: 40px;
  left: 0;  
  right: 0;  
  background-color: #FFFFFF;  
  border: 1px solid #0C55B8; 
  border-top: none;
  border-radius: 0 0 5px 5px;  
  max-height: 220px;  
  overflow-y: auto;  
  z-index: 1000;  
`;

const OptionItem = styled.div`  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  padding: 12px 8px;  
  cursor: pointer;  
  
  &:hover {  
    background-color: #F3F3F3;  
  }  
  
  span {  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 400;  
    font-size: 16px;  
    color: #323334;  
  }   
`;

const Divider = styled.div`  
  height: 0.5px;  
  background-color: #CBCCCD;  
  width: calc(100% - 1.6px);  
  position: relative;
  z-index: 1001;
  box-sizing: border-box;  
  margin: 0 0.8px 0 0.8px;
`;

const ScrollableContent = styled.div`  
  max-height: 219px;
  overflow-y: auto;  
`;

export default CustomDropdown;