import React, { useState, useMemo, useRef } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import CloseIcon from '../../assets/closeicon.svg';
import SortingArrows from '../widgets/SortArrows';
import ArrowRightGrey from '../../assets/arrowrightgrey.svg';
import ArrowLeftGrey from '../../assets/arrowleftgrey.svg';
import ArrowRightBlue from '../../assets/arrowrightblue.svg';
import ArrowLeftBlue from '../../assets/arrowleftblue.svg';

const RelatedIDsModal = ({ isOpen, onClose, serviceDesc, relatedServices }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'default' });
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 8;

    const sortedTableData = useMemo(() => {
        let sortableData = [...relatedServices];
        if (sortConfig.key !== null) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [sortConfig, relatedServices]);

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        return sortedTableData.slice(startIndex, startIndex + rowsPerPage);
    }, [sortedTableData, currentPage, rowsPerPage]);

    const totalPages = Math.ceil(sortedTableData.length / rowsPerPage);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
            setSortConfig({ key: null, direction: 'default' });
            return;
        }
        setSortConfig({ key, direction });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const generatePageNumbers = () => {
        const pages = [];
        if (totalPages <= 4) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, '...', totalPages);
            } else if (currentPage > totalPages - 3) {
                pages.push(1, '...', totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(1, '...', currentPage, '...', totalPages);
            }
        }
        return pages;
    };

    const modalContentRef = useRef(null);

    const handleOverlayClick = (event) => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <ModalOverlay id="analysis-modal-overlay" onClick={handleOverlayClick}>
            <ModalContent id="analysis-modal-content" ref={modalContentRef}>
                <ModalBody id="analysis-modal-body">
                    <CloseButton id="analysis-modal-close-button" onClick={onClose}>
                        <img id="analysis-modal-close-icon" src={CloseIcon} alt="Close" />
                    </CloseButton>
                    <Header id="analysis-modal-header">
                        <Title id="analysis-modal-title">Related SESE IDs</Title>
                    </Header>
                    <Description id="analysis-modal-description">
                        Below are the related SESE ID(s) for <strong>{serviceDesc}</strong>.
                    </Description>
                    <TablePaginationWrapper id="analysis-modal-table-pagination-wrapper">
                        <Table id="analysis-modal-table">
                            <thead id="analysis-modal-table-head">
                                <TableRow id="analysis-modal-table-head-row">
                                    <TableHeader
                                        id="analysis-modal-table-header-sepyPrefix"
                                        onClick={() => handleSort('sepy_prefix')}
                                        isSorted={sortConfig.key === 'sepy_prefix'}
                                        sortDirection={sortConfig.direction}
                                    >
                                        SEPY Prefix
                                        <SortingArrows id="analysis-modal-sorting-arrows-sepyPrefix" columnKey="sepyPrefix" sortConfig={sortConfig} />
                                    </TableHeader>
                                    <TableHeader
                                        id="analysis-modal-table-header-serviceID"
                                        onClick={() => handleSort('service_id')}
                                        isSorted={sortConfig.key === 'service_id'}
                                        sortDirection={sortConfig.direction}
                                    >
                                        Service ID
                                        <SortingArrows id="analysis-modal-sorting-arrows-serviceID" columnKey="serviceID" sortConfig={sortConfig} />
                                    </TableHeader>
                                    <TableHeader
                                        id="analysis-modal-table-header-description"
                                        onClick={() => handleSort('service_desc')}
                                        isSorted={sortConfig.key === 'service_desc'}
                                        sortDirection={sortConfig.direction}
                                    >
                                        Service ID description
                                        <SortingArrows id="analysis-modal-sorting-arrows-description" columnKey="description" sortConfig={sortConfig} />
                                    </TableHeader>
                                </TableRow>
                            </thead>
                            <tbody id="analysis-modal-table-body">
                                {paginatedData.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan="3">
                                            No related services found
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((row, index) => (
                                        <TableRow id={`analysis-modal-table-row-${index}`} key={index} isEven={index % 2 === 0}>
                                            <TableCell id={`analysis-modal-table-cell-sepyPrefix-${index}`}>{row.sepy_prefix}</TableCell>
                                            <TableCell id={`analysis-modal-table-cell-serviceID-${index}`}>{row.service_id}</TableCell>
                                            <TableCell id={`analysis-modal-table-cell-description-${index}`}>{row.service_desc}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </tbody>
                        </Table>
                        {totalPages > 1 && (
                            <PaginationContainer id="analysis-modal-pagination-container">
                                <PaginationTextButtonPrevious
                                    id="analysis-modal-pagination-prev"
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    <ArrowContainer id="analysis-modal-pagination-prev-arrow">
                                        <img
                                            id="analysis-modal-pagination-prev-icon"
                                            src={currentPage === 1 ? ArrowLeftGrey : ArrowLeftBlue}
                                            alt="Previous"
                                        />
                                    </ArrowContainer>
                                    Previous
                                </PaginationTextButtonPrevious>
                                <Divider id="analysis-modal-pagination-divider-1" />
                                {generatePageNumbers().map((page, index) => (
                                    <PaginationButton
                                        id={`analysis-modal-pagination-button-${index}`}
                                        key={index}
                                        active={page === currentPage}
                                        onClick={() => typeof page === 'number' && handlePageChange(page)}
                                        disabled={page === '...'}
                                    >
                                        {page}
                                    </PaginationButton>
                                ))}
                                <Divider id="analysis-modal-pagination-divider-2" />
                                <PaginationTextButtonNext
                                    id="analysis-modal-pagination-next"
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    isNext
                                >
                                    Next
                                    <ArrowContainer id="analysis-modal-pagination-next-arrow">
                                        <img
                                            id="analysis-modal-pagination-next-icon"
                                            src={currentPage === totalPages ? ArrowRightGrey : ArrowRightBlue}
                                            alt="Next"
                                        />
                                    </ArrowContainer>
                                </PaginationTextButtonNext>
                            </PaginationContainer>
                        )}
                    </TablePaginationWrapper>
                </ModalBody>
            </ModalContent>
        </ModalOverlay >,
        document.body
    );
};

const ModalOverlay = styled.div`  
    position: fixed;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    background: rgba(75, 77, 79, 0.6);  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    z-index: 1000;  
`;

const ModalContent = styled.div`  
    background: #ffffff;  
    width: 776px;  
    border-radius: 4px;  
    position: relative;  
    padding: 24px;
`;

const CloseButton = styled.button`  
    position: absolute;  
    top: 28px;  
    right: 24px;  
    background: none;  
    border: none;   
    cursor: pointer;  
`;

const ModalBody = styled.div`  
    display: flex;  
    flex-direction: column;  
    justify-content: space-between;  
    height: 100%;
`;

const Header = styled.div`  
    display: flex;  
    flex-direction: column;  
    align-items: flex-start;  
    margin-bottom: 16px;
`;

const Title = styled.h2`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 25.63px;  
    line-height: 32px;  
    display: flex;  
    align-items: center;  
    color: #002677;  
`;

const Description = styled.p`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 400;  
    font-size: 16px;  
    line-height: 20px;  
    color: #323334;  
`;

const Table = styled.table`  
    width: 100%;  
    border-collapse: collapse;  
    margin-top: 32px;  
`;

const TableRow = styled.tr`  
    display: flex;  
    width: 100%;  
    background: ${({ isEven }) => (isEven ? '#FAFAFA' : '#FFFFFF')};  
`;

const TableHeader = styled.th`  
    flex: 1;  
    display: flex;  
    align-items: center;  
    padding: 12px;  
    background: ${({ isSorted }) => isSorted ? '#EEF4FF' : '#F3F3F3'};  
    border-right: 1px solid #CBCCCD; 
    border-top: 1px solid #CBCCCD;
    border-bottom: 1px solid #CBCCCD; 
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 16px;  
    line-height: 20px;  
    color: #323334;  
    cursor: pointer;  
    position: relative;
  
    &:first-child {  
        border-left: 1px solid #CBCCCD;  
    }  
  
    .header-content {  
        display: flex;  
        align-items: center;  
        gap: 8px;  
    }  
  
    &:nth-child(1) { flex: 150px; }  
    &:nth-child(2) { flex: 150px; }  
    &:nth-child(3) { flex: 436px; }  
  
    &::before, &::after {  
        content: '';  
        position: absolute;  
        left: 0;  
        right: 0;  
        height: 3px;  
        background-color: transparent;  
    }  
  
    &::before {  
        top: 0;  
        background-color: ${({ isSorted, sortDirection }) => isSorted && sortDirection === 'ascending' ? '#0C55B8' : 'transparent'};  
    }  
  
    &::after {  
        bottom: 0;  
        background-color: ${({ isSorted, sortDirection }) => isSorted && sortDirection === 'descending' ? '#0C55B8' : 'transparent'};  
    }  
`;

const TableCell = styled.td`  
    flex: 1;  
    padding: 12px;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 400;  
    font-size: 14px;  
    line-height: 18px;  
    color: #323334;  
    border-bottom: 1px solid #CBCCCD;  
    border-right: 1px solid #CBCCCD;  
    &:first-child {  
        border-left: 1px solid #CBCCCD;  
    }  

    &:nth-child(1) { flex: 150px; }  
    &:nth-child(2) { flex: 150px; }  
    &:nth-child(3) { flex: 436px; }  
`;

const PaginationContainer = styled.div`  
  display: flex;  
  justify-content: flex-end;  
  align-items: center;  
  padding: 0;  
  width: fit-content;  
  height: 36px;  
  background: #FFFFFF;  
  border: 1px solid #CBCCCD;  
  border-radius: 5px;  
  margin-top: 24px;  
  margin-bottom: 16px;  
  align-self: flex-end;  
`;

const TablePaginationWrapper = styled.div`  
    display: flex;  
    flex-direction: column;  
    justify-content: space-between;  
    height: 100%;  
`;

const PaginationButton = styled.button`  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  padding: 8px 12px;  
  background: ${({ active }) => (active ? '#FFFFFF' : '#FFFFFF')};  
  color: ${({ active }) => (active ? '#323334' : '#0C55B8')};  
  border: ${({ active }) => (active ? '1px solid #7D7F81' : 'none')};   
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 14px;  
  line-height: 18px;  
  &:disabled {  
    pointer-events: none;  
  }  
  &:hover {  
    background-color: #F3F3F3;  
  }  
`;

const PaginationTextButtonNext = styled.button`  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  padding: 8px;  
  background: #FFFFFF;  
  color: ${({ disabled }) => (disabled ? '#929496' : '#0C55B8')};  
  border: none;  
  border-radius: 5px;  
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 14px;  
  line-height: 18px;  
  &:disabled {  
    pointer-events: none;  
  }  
  &:hover {  
    background-color: #F3F3F3; 
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; 
  }  
  img {  
    margin: ${({ isNext }) => (isNext ? '0 0 0 8px' : '0 8px 0 0')};  
  }  
`;

const PaginationTextButtonPrevious = styled.button`  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  padding: 8px;  
  background: #FFFFFF;  
  color: ${({ disabled }) => (disabled ? '#929496' : '#0C55B8')};  
  border: none;  
  border-radius: 5px;  
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 14px;  
  line-height: 18px;  
  &:disabled {  
    pointer-events: none;  
  }  
  &:hover {  
    background-color: #F3F3F3;  
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }  
  img {  
    margin: ${({ isNext }) => (isNext ? '0 0 0 8px' : '0 8px 0 0')};  
  }  
`;

const Divider = styled.div`  
  width: 1px;  
  height: 24px;  
  background-color: #CBCCCD;
`;

const ArrowContainer = styled.div`  
    width: 20px;  
    display: flex;  
    justify-content: center;  
    align-items: center;  
`;

export default RelatedIDsModal;