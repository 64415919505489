import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import RightArrow from '../../assets/rightarrow.svg';
import ZoomInBlue from '../../assets/zoominblue.svg';
import ZoomOutBlue from '../../assets/zoomoutblue.svg';
import ZoomOutGrey from '../../assets/zoomoutgrey.svg';
import ZoomInGrey from '../../assets/zoomingrey.svg';
import UnlinkIcon from '../../assets/unlink.svg';
import LinkIcon from '../../assets/link.svg';

function NavBar({ productId, uniquePrefixes, isLinked, handleLinkToggle, handleZoomIn, handleZoomOut, zoomLevel }) {
    const navigate = useNavigate();
    const isZoomInDisabled = zoomLevel >= 3.0;
    const isZoomOutDisabled = zoomLevel <= 0.5;

    return (
        <>
            <GlobalStyle />
            <NavBarContainer id="analysis-navbar-container">
                <NavLeft id="analysis-navbar-nav-left">
                    <Breadcrumbs id="analysis-navbar-breadcrumbs">
                        <BreadcrumbItem id="analysis-navbar-breadcrumb-home" onClick={() => navigate('/queue')}>
                            Home
                        </BreadcrumbItem>
                        <BreadcrumbSeparator id="analysis-navbar-breadcrumb-separator-1">
                            <img src={RightArrow} alt="Right Arrow" />
                        </BreadcrumbSeparator>
                        <BreadcrumbItem id="analysis-navbar-breadcrumb-plan-name" onClick={() => navigate('/queue/selection', { state: { productId } })}>
                            {productId}
                        </BreadcrumbItem>
                        <BreadcrumbSeparator id="analysis-navbar-breadcrumb-separator-2">
                            <img src={RightArrow} alt="Right Arrow" />
                        </BreadcrumbSeparator>
                        <BreadcrumbCurrent id="analysis-navbar-breadcrumb-current">
                            {uniquePrefixes.join(', ')}
                        </BreadcrumbCurrent>
                    </Breadcrumbs>
                </NavLeft>
                <NavCenter id="analysis-navbar-nav-center">
                    <NavButton id="analysis-navbar-link-toggle-button" onClick={handleLinkToggle}>
                        <img
                            src={isLinked ? UnlinkIcon : LinkIcon}
                            alt={isLinked ? "Unlink Icon" : "Link Icon"}
                            style={{ marginRight: '4px' }}
                        />
                        {isLinked ? 'Unlink page scrolling' : 'Link page scrolling'}
                    </NavButton>
                    <Divider id="analysis-navbar-divider" />
                    <NavButton
                        id="analysis-navbar-zoom-in-button"
                        onClick={handleZoomIn}
                        disabled={isZoomInDisabled}
                        style={{ color: isZoomInDisabled ? '#929496' : '#0C55B8' }}
                    >
                        <img
                            src={isZoomInDisabled ? ZoomInGrey : ZoomInBlue}
                            alt="Zoom In Icon"
                            style={{ marginRight: '4px' }}
                        />
                        Zoom in
                    </NavButton>
                    <NavButton
                        id="analysis-navbar-zoom-out-button"
                        onClick={handleZoomOut}
                        disabled={isZoomOutDisabled}
                        style={{ color: isZoomOutDisabled ? '#929496' : '#0C55B8' }}
                    >
                        <img
                            src={isZoomOutDisabled ? ZoomOutGrey : ZoomOutBlue}
                            alt="Zoom Out Icon"
                            style={{ marginRight: '4px' }}
                        />
                        Zoom out
                    </NavButton>
                </NavCenter>
            </NavBarContainer>
        </>
    );
}

const GlobalStyle = createGlobalStyle`  
    * {  
        margin: 0;  
        padding: 0;  
        box-sizing: border-box;  
    }  
`;

const NavBarContainer = styled.div`  
    display: flex;  
    flex-direction: row;  
    justify-content: space-between;  
    align-items: center;  
    padding: 0 32px;  
    background: #FFFFFF;  
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);  
    width: 100%;  
    height: 56px;  
    position: absolute;  
    top: 111px;  
    left: 0;  
    box-sizing: border-box;  
`;

const NavLeft = styled.div`  
    display: flex;  
    align-items: center;  
`;

const NavCenter = styled.div`  
    display: flex;  
    align-items: center;  
    justify-content: center;  
    position: absolute;  
    left: 50%;  
    transform: translateX(-50%);  
`;

const Breadcrumbs = styled.div`  
    display: flex;  
    align-items: center;  
    gap: 8px;  
`;

const BreadcrumbItem = styled.span`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 700;  
    font-size: 14px;  
    color: #0C55B8;  
    text-decoration: underline;  
    cursor: pointer;
`;

const BreadcrumbSeparator = styled.span`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 700;  
    font-size: 14px;  
    color: #4B4D4F;  
`;

const BreadcrumbCurrent = styled.span`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 400;  
    font-size: 14px;  
    color: #4B4D4F;  
`;

const NavButton = styled.button`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-weight: 400;  
    font-size: 14px;  
    color: #0C55B8;  
    background: #FFFFFF;  
    border: none;  
    cursor: pointer;  
    padding: 8px;  
    border-radius: 4px;  
    margin-left: 4px;  
    display: flex;  
    align-items: center;  
    &:hover {  
        background-color: ${props => (props.disabled ? 'inherit' : '#F3F3F3')};  
    }  
    &:disabled {  
        cursor: default;  
    }  
`;

const Divider = styled.div`  
    width: 1px;  
    height: 22px;  
    background-color: #D8D8D8;  
    margin: 0 0 0 4px;  
`;

export default NavBar;