import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import EditIcon from '../../assets/edit.svg';
import EditBlueIcon from '../../assets/editblue.svg';
import EditHovericon from '../../assets/editiconhover.svg';
import MagnifyIcon from '../../assets/search.svg';
import { useState, useRef } from 'react';
import BulkEditModal from '../widgets/BulkUserEditModal';
import SortingArrows from '../widgets/SortArrows';
import MagnifyGlass from '../../assets/searchiconbulk.svg';
import ExpandLess from '../../assets/expandless.svg';
import ExpandMore from '../../assets/expandmore.svg';
import CheckMark from '../../assets/checkmarksmall.svg';
import ReopenIcon from '../../assets/reopennormal.svg';
import ReopenHover from '../../assets/reopenhover.svg';
import PopupMenu from './PopupMenu';
import ReopenPlansModal from './ReopenPlansModal';
import ReopenIndividualPlanModal from './ReopenIndividualPlanModal';
import CheckboxPlain from '../../assets/checkbox.svg';
import CheckboxSelected from '../../assets/checkboxselected.svg';
import CheckboxIntermediate from '../../assets/checkboxinter.svg';
import { keyframes, css } from 'styled-components';
import { fetchSubmittedData } from '../../services/endpoints';

const GlobalStyle = createGlobalStyle`  
  * {  
    margin: 0;  
    padding: 0;  
    box-sizing: border-box;  
  }  
  body {  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
  }  
`;

const SubmittedPage = () => {

  const [plans, setPlans] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const formatDate = (utcDateString) => {
    if (!utcDateString) return '';
    const utcDate = new Date(utcDateString);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
    return localDate.toLocaleDateString();
  };

  useEffect(() => {
    const loadSubmittedData = () => {
      setIsLoading(true);
      fetchSubmittedData((data) => {
        if (data && data.status_code === "0" && data.data.length === 0) {
          setErrorMessage(data.status_message);
          setPlans([]);
        } else if (data && data.status_code === "0") {
          const formattedData = data.data.map((item, index) => ({
            id: index,
            name: item.product_id || '',
            owner: item.owner || '',
            date: formatDate(item.submitted_on) || ''
          }));
          setPlans(formattedData);
          setErrorMessage('');
        }
        setIsLoading(false);
      });
    };
    loadSubmittedData();
  }, []);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIntermediate, setIsIntermediate] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleCheckboxToggle = (id) => {
    setSelectedCheckboxes((prev) => {
      const newSelection = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];
      setIsIntermediate(newSelection.length > 0 && newSelection.length < plans.length);
      return newSelection;
    });
  };
  const handleTopCheckboxChange = () => {
    if (isIntermediate || selectedCheckboxes.length === plans.length) {
      setSelectedCheckboxes([]);
      setIsIntermediate(false);
    } else {
      setSelectedCheckboxes(plans.map((plan) => plan.id));
      setIsIntermediate(false);
    }
  };

  React.useEffect(() => {
    if (selectedCheckboxes.length > 0 && selectedCheckboxes.length < plans.length) {
      setIsIntermediate(true);
    } else {
      setIsIntermediate(false);
    }
  }, [selectedCheckboxes, plans.length]);

  const isBulkEditActive = selectedCheckboxes.length > 1;

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const [dropdownSearchTerm, setDropdownSearchTerm] = useState('');
  const handleDropdownSearchChange = (event) => {
    setDropdownSearchTerm(event.target.value);
  };

  const filteredPlans = plans.filter(plan =>
    plan.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedRows = plans.filter((plan, idx) => selectedCheckboxes.includes(idx));
  const owners = ['First Name', 'Second Name', 'Third Name', 'Fourth Name'];
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'default' });
  const sortedPlans = React.useMemo(() => {
    let sortablePlans = [...filteredPlans];
    if (sortConfig.key !== null) {
      sortablePlans.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePlans;
  }, [filteredPlans, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
      setSortConfig({ key: null, direction: 'default' });
      return;
    }
    setSortConfig({ key, direction });
  };

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState('');
  const dropdownRef = useRef(null);

  const handleDropdownToggle = (index) => {
    if (isDropdownOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsDropdownOpen(false);
        setOpenDropdownIndex(null);
        setIsClosing(false);
      }, 200);
    } else {
      setOpenDropdownIndex(index);
      setIsDropdownOpen(true);
      if (plans[index] && plans[index].owner) {
        setSelectedOwner(plans[index].owner);
      }
    }
  };

  const handleOptionSelect = (owner) => {
    setPlans((prevPlans) => prevPlans.map((plan, index) =>
      index === openDropdownIndex ? { ...plan, owner } : plan
    ));
    setSelectedOwner(owner);
    setIsClosing(true);
    setTimeout(() => {
      setIsDropdownOpen(false);
      setOpenDropdownIndex(null);
      setIsClosing(false);
    }, 200);
  };

  const filteredOwners = owners.filter((owner) =>
    owner.toLowerCase().includes(dropdownSearchTerm.toLowerCase())
  );

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setOpenDropdownIndex(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 12;

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev * rowsPerPage < filteredPlans.length ? prev + 1 : prev));
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedPlans.slice(indexOfFirstRow, indexOfLastRow);

  const bulkEditRef = useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (bulkEditRef.current && !bulkEditRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
  const [plansToReopen, setPlansToReopen] = useState([]);
  const [isIndividualReopenModalOpen, setIsIndividualReopenModalOpen] = useState(false);
  const [onePlanToReopen, setOnePlanToReopen] = useState(null);

  const handleReopenPlansClick = () => {
    setPlansToReopen(selectedRows);
    setIsReopenModalOpen(true);
  };

  const handleReopenIndividualPlanClick = (plan) => {
    setOnePlanToReopen(plan);
    setIsIndividualReopenModalOpen(true);
  };

  const [isClosing, setIsClosing] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  return (
    <>
      <GlobalStyle />
      <Container id="submitted-container">
        <Heading id="submitted-heading">
          <Title id="submitted-title">Submitted Plans</Title>
          <HelperText id="submitted-helper-text">View all submitted plans below. Reopen a plan to make any additional changes.</HelperText>
        </Heading>
        <Actions id="submitted-actions">
          <SearchTool id="submitted-search-tool" isActive={isSearchActive}>
            <SearchInput
              id="submitted-search-input"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={() => setIsSearchActive(true)}
              onBlur={() => setIsSearchActive(false)}
            />
            <SearchIcon id="submitted-search-icon" src={MagnifyIcon} alt="Search" />
          </SearchTool>
          <BulkEditTool
            id="submitted-bulk-edit-tool"
            active={isBulkEditActive}
            onClick={() => isBulkEditActive && setIsPopupOpen(!isPopupOpen)}
            ref={bulkEditRef}
          >
            <BulkEditIcon id="submitted-bulk-edit-icon" src={EditIcon} alt="Edit" active={isBulkEditActive} />
            <BulkEditText id="submitted-bulk-edit-text" active={isBulkEditActive}>Bulk actions</BulkEditText>
            {isPopupOpen && (
              <PopupMenu
                onClose={() => setIsPopupOpen(false)}
                id="submitted-popup-menu"
                onModifyOwnersClick={() => {
                  setIsModalOpen(true);
                  setIsPopupOpen(false);
                }}
                onReopenPlansClick={handleReopenPlansClick}
              />
            )}
          </BulkEditTool>
        </Actions>
        <Table id="submitted-table">
          <TableRow id="submitted-table-row-header" index={0}>
            <TableCellHeader
              id="submitted-table-cell-header-checkbox"
              center
              isSorted={sortConfig.key === null}
              sortDirection={null}
              isCheckbox
              isSortable={false}
            >
              <Checkbox
                id="submitted-top-checkbox"
                onClick={handleTopCheckboxChange}
                checked={selectedCheckboxes.length === plans.length && !isIntermediate}
                indeterminate={isIntermediate}
                disabled={plans.length === 0}
              />
            </TableCellHeader>
            <TableCellHeader
              id="submitted-table-cell-header-name"
              onClick={() => handleSort('name')}
              isSorted={sortConfig.key === 'name'}
              sortDirection={sortConfig.key === 'name' ? sortConfig.direction : null}
              isSortable={true}
            >
              Plan
              <SortingArrows id="submitted-sorting-arrows-name" columnKey="name" sortConfig={sortConfig} />
            </TableCellHeader>
            <TableCellHeader
              id="submitted-table-cell-header-owner"
              onClick={() => handleSort('owner')}
              isSorted={sortConfig.key === 'owner'}
              sortDirection={sortConfig.key === 'owner' ? sortConfig.direction : null}
              isSortable={true}
            >
              Owner
              <SortingArrows id="submitted-sorting-arrows-owner" columnKey="owner" sortConfig={sortConfig} />
            </TableCellHeader>
            <TableCellHeader
              id="submitted-table-cell-header-date"
              onClick={() => handleSort('date')}
              isSorted={sortConfig.key === 'date'}
              sortDirection={sortConfig.key === 'date' ? sortConfig.direction : null}
              isSortable={true}
            >
              Submitted on
              <SortingArrows id="submitted-sorting-arrows-date" columnKey="date" sortConfig={sortConfig} />
            </TableCellHeader>
            <TableCellHeader id="submitted-table-cell-header-changes">
              Action
            </TableCellHeader>
          </TableRow>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan="6">Loading...</TableCell>
            </TableRow>
          ) : errorMessage ? (
            <TableRow>
              <TableCell colSpan="6">{errorMessage}</TableCell>
            </TableRow>
          ) : (
            currentRows.map((plan, idx) => (
              <TableRow id={`submitted-table-row-${idx}`} key={plan.id} index={idx + 1}>
                <TableCell id={`submitted-table-cell-checkbox-${idx}`} center isCheckbox>
                  <Checkbox
                    id={`submitted-checkbox-${idx}`}
                    onClick={() => handleCheckboxToggle(plan.id)}
                    checked={selectedCheckboxes.includes(plan.id)}
                  />
                </TableCell>
                <TableCell id={`submitted-table-cell-name-${idx}`}>
                  <PlanNameContainer id={`submitted-plan-name-container-${idx}`}>
                    {plan.name}
                  </PlanNameContainer>
                </TableCell>
                <TableCell
                  id={`submitted-table-cell-owner-${idx}`}
                  isOwnerColumn
                  onClick={() => handleDropdownToggle(idx)}
                  style={{ position: 'relative' }}
                >
                  {openDropdownIndex === idx && isDropdownOpen ? (
                    <DropdownContainer id={`submitted-dropdown-container-${idx}`} ref={dropdownRef}>
                      <DropdownHeader id={`submitted-dropdown-header-${idx}`} isDropdownOpen={isDropdownOpen} onClick={handleDropdownToggle}>
                        <>
                          <SearchIcon id={`submitted-search-icon-${idx}`} src={MagnifyGlass} alt="Search" />
                          <SearchInputDropdown
                            id={`submitted-search-input-dropdown-${idx}`}
                            type="text"
                            placeholder="Search"
                            value={dropdownSearchTerm}
                            onChange={handleDropdownSearchChange}
                            autoFocus
                          />
                          <ExpandLessIcon id={`submitted-expand-less-icon-${idx}`} src={ExpandLess} onClick={handleDropdownToggle} />
                        </>
                      </DropdownHeader>
                      {isDropdownOpen && (
                        <DropdownList id={`queue-dropdown-list-${idx}`} isClosing={isClosing}>
                          <Divider />
                          {filteredOwners.map((owner, index) => (
                            <DropdownItem
                              id={`queue-dropdown-item-${openDropdownIndex}-${index}`}
                              key={index}
                              onClick={() => handleOptionSelect(owner)}
                            >
                              {owner}
                              {selectedOwner === owner && <CheckIcon id={`check-icon-${openDropdownIndex}`} src={CheckMark} alt="Selected" />}
                            </DropdownItem>
                          ))}
                        </DropdownList>
                      )}
                    </DropdownContainer>
                  ) : (
                    <>
                      {plan.owner}
                      <ExpandIconWrapper id={`submitted-expand-icon-wrapper-${idx}`}>
                        <ExpandIcon id={`submitted-expand-icon-${idx}`} src={ExpandMore} alt="Expand" />
                      </ExpandIconWrapper>
                    </>
                  )}
                </TableCell>
                <TableCell id={`submitted-table-cell-date-${idx}`}>{plan.date}</TableCell>
                <TableCell id={`submitted-table-cell-changes-${idx}`}>
                  <Badge id={`submitted-badge-${idx}`} onClick={() => handleReopenIndividualPlanClick(plan)}>
                    <ReopenImage id={`submitted-reopen-icon-${idx}`} />
                    <BadgeText id={`submitted-badge-text-${idx}`}>Reopen plan</BadgeText>
                  </Badge>
                </TableCell>
              </TableRow>
            ))
          )}
        </Table>
        <Pagination id="submitted-pagination">
          <PageInfo id="submitted-page-info">
            Displaying Page: <b id="submitted-current-page">{currentPage} of {Math.ceil(filteredPlans.length / rowsPerPage)}</b>
          </PageInfo>
          <PageButton id="submitted-previous-button" onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </PageButton>
          <PageButton id="submitted-next-button" onClick={handleNextPage} disabled={currentPage * rowsPerPage >= filteredPlans.length}>
            Next
          </PageButton>
        </Pagination>
        <BulkEditModal
          id="submitted-bulk-edit-modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedCount={selectedCheckboxes.length}
          selectedRows={selectedRows}
          owners={owners}
        />
        <ReopenPlansModal
          isOpen={isReopenModalOpen}
          id="submitted-reopen-plans-modal"
          onClose={() => setIsReopenModalOpen(false)}
          plans={plansToReopen}
          onConfirm={() => {
            setIsReopenModalOpen(false);
          }}
        />
        <ReopenIndividualPlanModal
          isOpen={isIndividualReopenModalOpen}
          id="submitted-reopen-individual-plans-modal"
          onClose={() => setIsIndividualReopenModalOpen(false)}
          plan={onePlanToReopen}
          onConfirm={() => {
            setIsIndividualReopenModalOpen(false);
          }}
        />
      </Container>
    </>
  );
};

const Container = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 0px;  
  gap: 24px;  
  width: calc(100% - 24px);  
  margin: 4px 12px -8px 12px; 
`;

const Heading = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 0px;  
  gap: 8px;  
  width: 1272px;  
  height: 61px;  
`;

const Title = styled.h1`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 26px;  
  line-height: 32px;  
  color: #002677;  
`;

const HelperText = styled.p`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 14px;  
  line-height: 18px;  
  color: #323334;  
`;

const Actions = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  gap: 16px;  
  width: 1376px;  
  height: 40px;  
`;

const SearchTool = styled.div`  
  display: flex;  
  align-items: center;  
  width: 260px;  
  height: 40px;  
  background: #ffffff;  
  border: 1px solid ${({ isActive }) => (isActive ? '#0C55B8' : '#4b4d4f')};  
  border-radius: 39px;  
  padding: 0 16px;  
  gap: 8px;  
  
  &:hover {  
    border-color: #0c55b8;  
  }  
`;

const BulkEditTool = styled.div`  
  display: flex;  
  align-items: center;  
  padding: 8px;  
  gap: 4px;  
  width: 186px;  
  height: 36px;  
  background: #ffffff;  
  border-radius: 4px;  
  color: ${({ active }) => (active ? '#0c55b8' : '#929496')};  
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};  
  
  ${({ active }) => active && `  
    &:hover {  
      color: #002677;  
  
      ${BulkEditIcon} {  
        content: url(${EditHovericon});  
      }  
  
      ${BulkEditText} {  
        color: #002677;  
      }  
    }  
  `}  
`;

const BulkEditText = styled.span`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;  
  color: ${({ active }) => (active ? '#0c55b8' : '#929496')}; 
`;

const BulkEditIcon = styled.img`  
  content: ${({ active }) => (active ? `url(${EditBlueIcon})` : `url(${EditIcon})`)};  
`;

const Table = styled.div`  
  width: 100%;  
  border-collapse: collapse;  
`;

const TableRow = styled.div`  
  display: flex;  
  width: 100%;  
  height: ${({ index }) => (index === 0 ? '52.6px' : '49.6px')};  
  background: ${({ index }) => (index % 2 === 0 ? '#ffffff' : '#fafafa')};  
  border-bottom: 1px solid #cbcccd;  
  border-right: 1px solid #cbcccd;
  border-left: 1px solid #cbcccd;
`;

const TableCellHeader = styled.div`  
  display: flex;  
  flex-direction: row;  
  align-items: center;  
  padding: 16px 8px;  
  width: 200px;  
  height: 52px;  
  background: ${({ isSorted, isCheckbox }) => isCheckbox ? '#FFFFFF' : (isSorted ? '#EEF4FF' : '#FFFFFF')};  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;  
  color: #323334;  
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'default')};  
  border-right: 1px solid #cbcccd; 
  border-top: 1px solid #cbcccd;  
  
  &:nth-child(1) { flex: 2.62; }      
  &:nth-child(2) { flex: 53.78; }      
  &:nth-child(3) { flex: 200px; }      
  &:nth-child(4) { flex: 200px; }      
  &:nth-child(5) { flex: 200px; }  
  
  ${({ center }) => center && `justify-content: center; align-items: center;`}      
  &:last-child { border-right: none; }  
  
  position: relative;  
  
  &::before, &::after {  
    content: '';  
    position: absolute;  
    left: 0;  
    right: 0;  
    height: 3px;  
    background-color: transparent;  
  }  
  
  &::before {  
    top: 0;  
    background-color: ${({ isSorted, sortDirection }) => isSorted && sortDirection === 'ascending' ? '#196ECF' : 'transparent'};  
  }  
  
  &::after {  
    bottom: 0;  
    background-color: ${({ isSorted, sortDirection }) => isSorted && sortDirection === 'descending' ? '#196ECF' : 'transparent'};  
  }  
`;

const ExpandIconWrapper = styled.div`  
  position: absolute;  
  right: 8px;  
  top: 50%;  
  transform: translateY(-50%);  
  visibility: hidden;
`;

const TableCell = styled.div`  
  padding: 16px 8px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-size: 14px;  
  color: #323334;  
  border-right: 1px solid #cbcccd;  
  background: ${({ isCheckbox }) => (isCheckbox ? '#FFFFFF' : 'inherit')};  
  cursor: ${({ isOwnerColumn }) => (isOwnerColumn ? 'pointer' : 'default')};  
  position: relative;  
  
  &:nth-child(1) { flex: 2.62; }  
  &:nth-child(2) { flex: 53.78; }  
  &:nth-child(3) { flex: 200px; }  
  &:nth-child(4) { flex: 200px; }  
  &:nth-child(5) { flex: 200px; }
  
  ${({ center }) => center && `display: flex; justify-content: center; align-items: center;`}  
  &:last-child { border-right: none; }  
  
  &:hover ${ExpandIconWrapper} {  
    visibility: visible;
  }  
`;

const Checkbox = styled.div`  
  display: flex;  
  align-items: center;  
  justify-content: center;  
  width: 20px;  
  height: 20px;  
  background: ${({ checked, intermediate, disabled }) =>
    disabled ? `url(${CheckboxPlain})` :
      checked ? `url(${CheckboxSelected})` :
        intermediate ? `url(${CheckboxIntermediate})` : `url(${CheckboxPlain})`};  
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-size: cover;  
  background-repeat: no-repeat; 
  border: 1px solid transparent;
  border-radius: 2px;

  &:hover {  
    border-color: ${({ disabled }) => (disabled ? 'transparent' : '#0C55B8')};
  } 
`;

const DropdownContainer = styled.div`  
  position: relative;  
  width: 100%;
  top: -11px;
  z-index: 51;
`;

const DropdownHeader = styled.div`  
  display: flex;  
  align-items: center;  
  padding: 8px;  
  width: 100%;  
  height: 40px;  
  background: #FFFFFF;  
  border: 1px solid #7D7F81;  
  border-radius: 4px;  
  cursor: pointer; 
  gap: 4px; 
  ${({ isDropdownOpen }) => isDropdownOpen && `  
    border-color: #0C55B8;  
    border-bottom: none;  
    border-top-left-radius: 4px;  
    border-top-right-radius: 4px;  
    border-bottom-left-radius: 0;  
    border-bottom-right-radius: 0;  
  `}  
  position: relative;  
`;

const SearchIcon = styled.img`  
  width: 18px;  
  height: 18px;  
`;

const ExpandIcon = styled.img`  
  width: 11.15px;  
  height: 6.55px;  
  margin-left: auto;  
  transform: ${({ isDropdownOpen }) => (isDropdownOpen ? 'none' : 'matrix(-1, 0, 0, 1, 0, 0)')};  
  position: absolute;  
  right: 8px;  
  top: 50%;  
  transform: translateY(-50%) ${({ isDropdownOpen }) => (isDropdownOpen ? 'none' : 'matrix(-1, 0, 0, 1, 0, 0)')};  
`;

const ExpandLessIcon = styled.img`  
  width: 11.15px;  
  height: 6.55px;  
  position: absolute;  
  right: 8px;  
  top: 50%;  
  transform: translateY(-50%);  
  cursor: pointer;  
  z-index: 11;  
`;

const DropdownList = styled.ul`  
  position: absolute;  
  top: 100%;  
  left: 0;  
  right: 0;  
  background: #FFFFFF;  
  border-bottom: 1px solid #0C55B8;  
  border-radius: 0 0 4px 4px;  
  z-index: 10;  
  max-height: 200px;  
  overflow-y: auto;  
  padding: 0;  
  margin: 0;  
  list-style: none;  
  width: 100%;  
  background-color: #FFFFFF;  
  
  ${({ isClosing }) => isClosing && css`  
    animation: ${dissolveOut} 0.5s forwards;  
  `};  
  
  & > li:first-child { border-top: 1px solid #CBCCCD; }  
  & > li:last-child { border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; }  
`;

const SearchInput = styled.input`  
  width: calc(100% - 16px);  
  border: none;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;  
  
  &:focus {  
    outline: none;  
  }  
  
  &::placeholder {  
    color: #4b4d4f;  
  }  
`;

const DropdownItem = styled.li`  
  display: flex;  
  justify-content: space-between;  
  flex-direction: row;  
  align-items: center;  
  padding: 8px;  
  width: 100%;  
  height: 38px;  
  min-height: 38px;  
  background: #FFFFFF;  
  border-width: 0px 1px;  
  border-style: solid;  
  border-color: #0C55B8;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 400;  
  font-size: 14px;  
  line-height: 18px;  
  color: #323334;  
  cursor: pointer;  
  &:hover {  
    background: #F3F3F3;  
  }  
`;

const SearchInputDropdown = styled.input`  
  width: calc(100% - 16px);  
  border: none;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 14px;  
  line-height: 18px; 
  
  &:focus {  
    outline: none;  
  }  
  
  &::placeholder {  
    color: #6D6F70;  
  }  
`;

const CheckIcon = styled.img`  
  width: 10.33px;  
  height: 7.48px;  
`;

const ReopenImage = styled.div`  
  width: 16px;  
  height: 16px;  
  background-image: url(${ReopenIcon});  
  background-size: contain;  
  background-repeat: no-repeat;  
`;

const Badge = styled.div`  
  display: flex;  
  align-items: center;
  gap: 4px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;
  font-weight: 700;  
  font-size: 14px;  
  line-height: 18px;
  cursor: pointer;
  width: 105px;
  
  &:hover ${ReopenImage} {  
    background-image: url(${ReopenHover});
  }

  &:hover {
    border-bottom: 2px solid #003A8D;
  }
`;

const BadgeText = styled.span`  
  color: #0C55B8; 
  font-weight: 700;
  
  &:hover {  
    color: #003A8D;  
  }
`;

const PlanNameContainer = styled.div`  
  display: flex;  
  align-items: center;  
  gap: 8px;
`;

const Pagination = styled.div`  
  display: flex;  
  justify-content: flex-end;  
  align-items: center;
  padding: 16px 0px 8px 0px;
  margin-top: -24px;
  gap: 8px;  
  width: 100%;  
`;

const PageButton = styled.button`  
  display: flex;  
  align-items: center;  
  justify-content: center;  
  padding: 10px 24px;  
  height: 32px;  
  background: #FFFFFF;  
  border-radius: 999px;  
  border: 1px solid #323334;  
  cursor: pointer;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 14px; 
  line-height: 18px; 
  color: #323334;  
  
  &:disabled {  
    cursor: auto;  
    background-color: #F3F3F3;
    border: none;
    color: #929496;
  }  
  
  &:hover:not(:disabled) {  
    background: #F3F3F3;
    border-color: #000;  
    color: #000;
  }  
`;

const PageInfo = styled.span`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 14px;  
  color: #323334; 
  line-height: 18px; 
`;

const dissolveOut = keyframes`  
  from {  
    opacity: 1;  
  }  
  to {  
    opacity: 0;  
  }  
`;

const Divider = styled.div`  
  position: relative;  
  height: 0.8px;  
  background-color: #CBCCCD;  
  
  &::before,  
  &::after {  
    content: '';  
    position: absolute;  
    width: 1px;  
    height: 1px;  
    background-color: #0C55B8;  
  }  
  
  &::before {  
    left: 0;  
  }  
  
  &::after {  
    right: 0;  
  }  
`;

export default SubmittedPage;