import styled from 'styled-components';

const SortingArrows = ({ columnKey, sortConfig }) => {
  const isActive = sortConfig.key === columnKey;
  const isAscending = isActive && sortConfig.direction === 'ascending';
  const isDescending = isActive && sortConfig.direction === 'descending';

  const upColor = isAscending ? '#0C55B8' : isActive ? '#B5CCED' : '#929496';
  const downColor = isDescending ? '#0C55B8' : isActive ? '#B5CCED' : '#929496';

  return (
    <ArrowContainer id="arrow-container">
      <Arrow id="arrow-up" viewBox="0 0 9 6" color={upColor}>
        <path d="M1 5L4.5 1L8 5" />
      </Arrow>
      <Arrow id="arrow-down" viewBox="0 0 9 6" color={downColor}>
        <path d="M1 1L4.5 5L8 1" />
      </Arrow>
    </ArrowContainer>
  );
};

const ArrowContainer = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 0px;  
  gap: 6px;  
  width: 9px;  
  height: 18px;  
  margin-left: 8px;  
`;

const Arrow = styled.svg`  
  width: 9px;  
  height: 6px;  
  fill: none;  
  stroke: ${({ color }) => color};  
  stroke-width: 1.5px;  
`;

export default SortingArrows;