import LogoPanel from "./logoPanel";
import MenuPanel from "./menuPanel";
import styled from "styled-components";

function Header({ location }) {

    const showMenuPanel = location.pathname !== '/';

    return (
        <Root id="header-root">
            <LogoPanel id="header-logo-panel" />
            {showMenuPanel && <MenuPanel id="header-menu-panel" />}
        </Root>
    );
}

const Root = styled.div`  
  position: fixed;  
  top: 0;  
  height: 111px;  
  left: 0;  
  right: 0;  
  padding: 0 32px;  
  display: flex;  
  min-width: 508px;  
  flex-direction: column;  
  align-items: flex-start;  
  justify-content: center;  
  background-color: #FAF8F2;  
  z-index: 55;  
`;

export default Header;  
