import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import AsterickRed from '../../assets/redasterick.svg';
import SimpleDropdown from './SimpleDropdown';

const ContactUsSection = () => {
  const [message, setMessage] = useState('');
  const [focused, setFocused] = useState(false);
  const [inputText, setInputText] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const maxCharacters = 250;

  useEffect(() => {
    if (inputText.trim() && message.trim()) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [inputText, message]);

  return (
    <>
      <Header id="support-contact-header">
        <Title id="support-contact-title">Contact us</Title>
        <Subtitle id="support-contact-subtitle">Have a question? We’re here to help.</Subtitle>
      </Header>
      <ContentWrapper id="support-contact-content-wrapper">
        <TextAreaWrapper id="support-contact-textarea-wrapper" style={{ marginBottom: '48px' }}>
          <TextArea id="support-contact-textarea">
            <Label id="support-contact-label-1" style={{ marginBottom: '4px' }}>
              What can we help you with? <AsteriskImage id="support-contact-asterisk-image-1" src={AsterickRed} alt="Required" />
            </Label>
            <FieldContent
              id="support-contact-field-content-1"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              focused={inputFocused}
              hasText={!!inputText}
            >
              <InputText id="support-contact-input-text-1" maxLength="150" />
            </FieldContent>
          </TextArea>
        </TextAreaWrapper>
        <TextAreaContainer id="support-contact-textarea-container">
          <Label id="support-contact-label-2" style={{ marginBottom: '4px' }}>
            Describe your issue or question <AsteriskImage id="support-contact-asterisk-image-2" src={AsterickRed} alt="Required" />
          </Label>
          <InstructionText id="support-contact-instruction-text" style={{ marginBottom: '4px' }}>Include as much detail as possible.</InstructionText>
          <StyledTextArea
            id="support-contact-styled-textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength={maxCharacters}
            focused={focused}
            hasText={!!message}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            style={{ marginBottom: '4px' }}
          />
          <CharacterCount id="support-contact-character-count" style={{ marginBottom: '30px' }}>{maxCharacters - message.length} characters remaining</CharacterCount>
          <Button id="support-contact-button" disabled={!isButtonEnabled}>Send message</Button>
        </TextAreaContainer>
      </ContentWrapper>
    </>
  );
};

const LeaveFeedbackSection = () => {
  const [message, setMessage] = useState('');
  const [focused, setFocused] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const maxCharacters = 250;
  const options = ['Leave a compliment', 'Report a problem', 'Make a suggestion'];

  const getHeading = (category) => {
    switch (category) {
      case 'Leave a compliment':
        return 'What did you like?';
      case 'Report a problem':
        return 'What went wrong?';
      case 'Make a suggestion':
        return 'What can we do better?';
      default:
        return 'Details';
    }
  };

  useEffect(() => {
    if (selectedCategory.trim() && message.trim()) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [selectedCategory, message]);

  return (
    <>
      <Header id="support-feedback-header">
        <Title id="support-feedback-title">Leave feedback</Title>
        <Subtitle id="support-feedback-subtitle">Let us know your feedback.</Subtitle>
      </Header>
      <ContentWrapper id="support-feedback-content-wrapper">
        <TextAreaWrapper id="support-feedback-textarea-wrapper" style={{ marginBottom: '48px' }}>
          <TextArea id="support-feedback-textarea">
            <Label id="support-feedback-label-1" style={{ marginBottom: '4px' }}>
              Feedback category <AsteriskImage id="support-feedback-asterisk-image-1" src={AsterickRed} alt="Required" />
            </Label>
            <SimpleDropdown
              id="support-feedback-simple-dropdown"
              options={options}
              selectedOption={selectedCategory}
              onOptionSelect={setSelectedCategory}
              isDisabled={false}
            />
          </TextArea>
        </TextAreaWrapper>
        <TextAreaContainer id="support-feedback-textarea-container">
          <Label id="support-feedback-label-2" style={{ marginBottom: '4px' }}>
            {getHeading(selectedCategory)} <AsteriskImage id="support-feedback-asterisk-image-2" src={AsterickRed} alt="Required" />
          </Label>
          <StyledTextArea
            id="support-feedback-styled-textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength={maxCharacters}
            focused={focused}
            hasText={!!message}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            style={{ marginBottom: '4px' }}
          />
          <CharacterCount id="support-feedback-character-count" style={{ marginBottom: '30px' }}>{maxCharacters - message.length} characters remaining</CharacterCount>
          <Button id="support-feedback-button" disabled={!isButtonEnabled}>Submit</Button>
        </TextAreaContainer>
      </ContentWrapper>
    </>
  );
};

function MainSupportContent({ activeSection }) {
  return (
    <>
      <GlobalStyle id="support-main-globalstyles" />
      <Container id="support-main-container">
        {activeSection === 'contactUs' && <ContactUsSection />}
        {activeSection === 'leaveFeedback' && <LeaveFeedbackSection />}
      </Container>
    </>
  );
}

const GlobalStyle = createGlobalStyle`  
  * {  
    margin: 0;  
    padding: 0;  
    box-sizing: border-box;  
  }  
  body {  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
  }  
`;

const Container = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 0px;  
  position: relative;  
  width: 984px;  
  left: 300px;  
  padding-top: 33px;  
  gap: 32px;  
`;

const Header = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 24px 32px;  
  width: 984px;  
  background: #FBF9F4;  
  border-radius: 8px;  
  gap: 16px;  
`;

const Title = styled.h1`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 700;  
  font-size: 23px;  
  line-height: 28px;  
  color: #323334;  
`;

const Subtitle = styled.p`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;  
  color: #4B4D4F;  
`;

const ContentWrapper = styled.div`  
  width: 983px;  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 32px 32px 80px 32px;  
  position: relative;  
  border: 1px solid #CBCCCD;  
  border-radius: 8px;  
`;

const TextAreaWrapper = styled.div`  
  width: 653.34px;  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 0px;  
  position: relative;  
`;

const TextAreaContainer = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  width: 653px;  
`;

const StyledTextArea = styled.textarea`  
  width: 100%;
  height: 160px;  
  padding: 12px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-size: 16px;  
  border: 1px solid ${({ focused, hasText }) => (focused || hasText ? '#0C55B8' : '#4B4D4F')};  
  border-radius: 4px;  
  resize: none;  
  color: #4B4D4F;
    
  &:focus {  
    outline: none;  
    border-color: #0C55B8;  
  }  
    
  &:hover {  
    outline: none;  
    border-color: #0C55B8;  
  }  
`;

const TextArea = styled.div`  
  width: 653px;  
  display: flex;  
  flex-direction: column;  
  align-items: flex-start;  
  padding: 0px; 
`;

const Label = styled.label`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 20px;  
  color: #323334;  
  display: flex;  
  align-items: center;  
`;

const InstructionText = styled.p`  
  width: 653px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 400;  
  font-size: 14px;  
  line-height: 18px;  
  color: #4B4D4F;  
`;

const FieldContent = styled.div`  
  width: 653px;  
  height: 40px;  
  display: flex;  
  flex-direction: column;  
  justify-content: center;  
  align-items: center;  
  padding: 0px;  
  background: #FFFFFF;  
  border: 1px solid ${({ focused, hasText }) => (focused || hasText ? '#0C55B8' : '#4B4D4F')};
  border-radius: 4px;  

  &:focus {  
    outline: none;  
    border-color: #0C55B8;  
  }  
    
  &:hover {  
    outline: none;  
    border-color: #0C55B8;  
  }
`;

const InputText = styled.textarea`  
  width: 629px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 20px;  
  color: #4B4D4F;  
  border: none;  
  outline: none;  
  padding: 8px 12px;  
  resize: none;  
`;

const CharacterCount = styled.div`  
  width: 653.34px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 400;  
  font-size: 14px;  
  line-height: 18px;  
  color: #4B4D4F;  
  text-align: right;  
`;

const Button = styled.button`   
  display: flex;  
  justify-content: center;  
  align-items: center;  
  padding: 8px 24px;  
  background: ${({ disabled }) => (disabled ? '#F3F3F3' : '#002677')};  
  border-radius: 999px;  
  border: none;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-style: normal;  
  font-weight: 700;  
  font-size: 16px;  
  line-height: 24px;  
  color: ${({ disabled }) => (disabled ? '#929496' : '#FFFFFF')};  
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};  
  align-self: flex-end;  
    
  &:hover {  
    background: ${({ disabled }) => (disabled ? '#F3F3F3' : '#001D5B')};  
  }  
`;

const AsteriskImage = styled.img` 
  width: 7.87px; 
  height: 8.6px; 
  margin-left: 4px;
  margin-bottom: 6px;
`;

export default MainSupportContent;  